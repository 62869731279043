import { Container } from "@mui/material"
import Navbar from "./components/Navbar/Navbar"
import React from "react"

export default function AppLayout({ children }) {
  return (
    <>
      <Navbar />
      <div className='body'>
        <Container maxWidth='lg' sx={{ marginTop: 1 }}>
          {children}
        </Container>
      </div>
    </>
  )
}
