import { FormGroup, Select } from "@mui/material"

export default function SelectGroup({ id, label, value, onChange, children }) {
  return (
    <FormGroup>
      <label htmlFor={id} style={{ paddingBottom: "8px" }}>
        {label}
      </label>
      <Select
        fullWidth
        id={id}
        label={label}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      >
        {children}
      </Select>
    </FormGroup>
  )
}
