import { useQuery, useQueryClient } from "react-query"

import { teams } from "core/api/teams"
import { useAuth0 } from "@auth0/auth0-react"

export default function useTeams(page = 1, pageSize = 100) {
  const queryClient = useQueryClient()
  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const queryResponse = useQuery(["teams", { page, pageSize }], () =>
    getToken().then((token) => {
      return teams.getTeams(page, pageSize, token)
    })
  )

  const addTeam = async (body) => {
    const token = await getToken()
    return teams.addTeam(body, token).then((response) => {
      if (response != null) {
        queryClient.removeQueries("teams")
      }
      return response
    })
  }

  return {
    ...queryResponse,
    teams: queryResponse?.data?.items ?? [],
    totalCount: queryResponse?.data?.totalCount ?? 0,
    totalPages: queryResponse?.data?.totalPages ?? 0,
    addTeam,
  }
}
