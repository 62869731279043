import { CircularProgress, Grid, MenuItem, Typography } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"

import FormContainer from "components/Shared/FormContainer"
import InputGroup from "components/Shared/InputGroup"
import React from "react"
import SelectGroup from "components/Shared/SelectGroup"
import useConstructorManufacturer from "core/hooks/useConstructorManufacturer"
import useCountries from "core/hooks/useCountries"

export default function ConstructorManufacturersEditPage() {
  const params = useParams()

  const { countries } = useCountries()

  const {
    constructorManufacturer,
    isLoading,
    updateConstructorManufacturer,
    deleteConstructorManufacturer,
  } = useConstructorManufacturer(params.constructorManufacturerId)

  const navigate = useNavigate()

  const [name, setName] = React.useState("")
  const [countryId, setCountryId] = React.useState("")

  React.useEffect(() => {
    if (constructorManufacturer != null) {
      setName(constructorManufacturer.name)
      setCountryId(constructorManufacturer.country.id)
    }
  }, [constructorManufacturer])

  const handleDelete = async (event) => {
    event.preventDefault()
    await deleteConstructorManufacturer()
    navigate(`/constructor-manufacturers`)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const result = await updateConstructorManufacturer(name, countryId)

    if (result.status === 204) {
      navigate("/constructor-manufacturers")
      return
    }
  }

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <Grid container gap={2}>
      <Grid item xs={12}>
        <Typography variant='h4' component='h1'>
          Edit constructor manufacturer {constructorManufacturer.name}
        </Typography>
      </Grid>

      <FormContainer
        handleSubmit={handleDelete}
        buttonText='Delete constructor'
        buttonColor='error'
        justifyContent='flex-end'
      />

      <FormContainer handleSubmit={handleSubmit}>
        <Grid item xs={12}>
          <InputGroup
            id='constructor-manufacturer-id'
            label='Id'
            value={constructorManufacturer.id}
            disabled
          />
        </Grid>

        <Grid item xs={12}>
          <InputGroup
            id='constructor-manufacturer-name'
            label='Name'
            value={name}
            disabled={false}
            onChange={(value) => setName(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectGroup
            id='constructor-manufacturer-country'
            label='Country'
            value={countryId}
            onChange={(value) => setCountryId(value)}
          >
            {countries.map((country) => (
              <MenuItem key={country.id} value={country.id}>
                {country.name}
              </MenuItem>
            ))}
          </SelectGroup>
        </Grid>
      </FormContainer>
    </Grid>
  )
}
