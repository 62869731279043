import { withAuthenticationRequired } from '@auth0/auth0-react'
import React from 'react'

export const AuthenticationGuard = ({ component }) => {
  let Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="page-layout">
        <div>Loading...</div>
      </div>
    ),
  })

  return <Component />
}
