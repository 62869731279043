import "./index.scss";

export default function KeyValue({ title, values }) {
  if (title == null) {
    return;
  }

  return (
    <div className="key-value-container">
      <span className="key">{title}:</span>
      <div className="values">
        {values.map((value, index) => {
          if (value.value == null) {
            return <span key={index}></span>;
          }

          if (value.isImage === true) {
            return (
              <div key={index} className="value-image-container">
                <a href={value.link} className="value value-image-link">
                  {value.value}
                </a>
                <img src={value.link} alt={value.alt} className="value-image" />
              </div>
            );
          }

          if (value.isColor != null) {
            return (
              <div key={index} className="value-color-container">
                <span className="value">{value.value}</span>
                <span className="color" style={{ color: value.value }} />
              </div>
            );
          }

          if (value.link != null) {
            return (
              <a key={index} href={value.link} className="value">
                {value.value}
              </a>
            );
          }

          return (
            <span key={index} className="value">
              {value.value}
            </span>
          );
        })}
      </div>
    </div>
  );
}
