import { constructors } from "../../api/constructors"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "react-query"

export default function useConstructorHistory(constructorId, historyId) {
  const types = [
    { id: 0, value: "Car" },
    { id: 1, value: "LittleLogo" },
  ]

  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const updateConstructorHistory = async (body) => {
    const token = await getToken()
    return constructors.updateConstructorHistory(
      constructorId,
      historyId,
      body,
      token
    )
  }

  const queryResponse = useQuery(["constructor-histories", { historyId }], () =>
    getToken().then((token) => {
      return constructors.getConstructorHistoryById(
        constructorId,
        historyId,
        token
      )
    })
  )

  return {
    ...queryResponse,
    history: queryResponse.data,
    types,
    updateConstructorHistory,
  }
}
