import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material"

import FormContainer from "components/Shared/FormContainer"
import InputGroup from "components/Shared/InputGroup"
import React from "react"
import SelectGroup from "components/Shared/SelectGroup"
import useAllConstructorManufacturers from "core/hooks/useAllConstructorManufacturers"
import useAllConstructors from "core/hooks/useAllConstructors"
import useAllEngineManufacturers from "core/hooks/useAllEngineManufacturers"
import useCountries from "core/hooks/useCountries"
import { useNavigate } from "react-router-dom"
import useTeams from "core/hooks/useTeams"

export default function TeamAddPage() {
  const { addTeam } = useTeams()

  const { countries, isLoading: isLoadingCountries } = useCountries()
  const { constructorManufacturers, isLoading: isLoadingConstructors } =
    useAllConstructorManufacturers()
  const { engineManufacturers, isLoading: isLoadingEngineManufacturers } =
    useAllEngineManufacturers()

  const {
    constructors: legacyConstructors,
    isLoading: isLoadingConstructorsLegacy,
  } = useAllConstructors()

  const [fullName, setFullName] = React.useState("")
  const [shortName, setShortName] = React.useState("")
  const [color, setColor] = React.useState("")
  const [countryId, setCountryId] = React.useState("")
  const [constructors, setConstructors] = React.useState([])

  const navigate = useNavigate()

  React.useEffect(() => {
    if (
      countries?.length > 0 &&
      constructorManufacturers?.length > 0 &&
      engineManufacturers?.length > 0 &&
      legacyConstructors?.length > 0
    ) {
      setFullName("")
      setShortName("")
      setColor("")
      setCountryId(countries[0].id)
      setConstructors([
        {
          constructorId: constructorManufacturers[0].id,
          engineManufacturerId: engineManufacturers[0].id,
          constructorLegacyId: legacyConstructors[0].id,
        },
      ])
    }
  }, [
    constructorManufacturers,
    engineManufacturers,
    legacyConstructors,
    countries,
  ])

  if (
    isLoadingCountries ||
    isLoadingConstructors ||
    isLoadingEngineManufacturers ||
    isLoadingConstructorsLegacy
  ) {
    return <CircularProgress />
  }

  const handleAddConstructor = () => {
    setConstructors([
      ...constructors,
      {
        constructorId: constructors[0].constructorId,
        engineManufacturerId: constructors[0].engineManufacturerId,
        constructorLegacyId: constructors[0].constructorLegacyId,
      },
    ])
  }

  const removeConstructor = (index) => {
    const newConstructors = constructors.filter((_, i) => i !== index)
    setConstructors(newConstructors)
  }

  const setConstructorsValue = (value, index, property) => {
    const newConstructors = constructors.map((constructor, i) => {
      if (i === index) {
        return {
          ...constructor,
          [property]: value,
        }
      }

      return constructor
    })

    setConstructors(newConstructors)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const result = await addTeam({
      team: {
        fullName,
        shortName,
        color,
        countryId,
        constructors,
      },
    })
    if (result != null) {
      navigate(`/teams/${result.teamId}/create-team-histories`)
      return
    }
  }

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography variant='h4' component='h1'>
          Create team
        </Typography>
      </Grid>

      <FormContainer handleSubmit={handleSubmit}>
        <Grid item xs={12}>
          <InputGroup
            id={`full-name`}
            label='Full name'
            autocomplete={false}
            value={fullName}
            gridSize={12}
            onChange={(value) => setFullName(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <InputGroup
            id={`short-name`}
            label='Short name'
            autocomplete={false}
            value={shortName}
            gridSize={6}
            onChange={(value) => setShortName(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <InputGroup
            id={`color`}
            label='Color'
            autocomplete={false}
            value={color}
            gridSize={6}
            onChange={(value) => setColor(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectGroup
            id={`country`}
            label='Country'
            value={countryId}
            gridSize={6}
            onChange={(value) => setCountryId(value)}
          >
            {countries.map((country) => (
              <MenuItem key={country.id} value={country.id}>
                {country.name}
              </MenuItem>
            ))}
          </SelectGroup>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant={"outlined"}
            color={"primary"}
            onClick={handleAddConstructor}
          >
            Add constructor
          </Button>
        </Grid>

        {constructors?.map((constructor, index) => (
          <React.Fragment key={index}>
            {constructors.length > 1 && (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant={"outlined"}
                  color={"error"}
                  onClick={() => removeConstructor(index)}
                >
                  Remove constructor
                </Button>
              </Grid>
            )}

            <Grid item xs={12}>
              <SelectGroup
                id={`constructor-${index}`}
                label='Constructors'
                value={constructor.constructorId}
                gridSize={6}
                onChange={(value) =>
                  setConstructorsValue(value, index, "constructorId")
                }
              >
                {constructorManufacturers.map((constructor) => (
                  <MenuItem key={constructor.id} value={constructor.id}>
                    {constructor.name}
                  </MenuItem>
                ))}
              </SelectGroup>
            </Grid>

            <Grid item xs={12}>
              <SelectGroup
                id={`engine-manufacturer-${index}`}
                label='Engine manufacturers'
                value={constructor.engineManufacturerId}
                gridSize={6}
                onChange={(value) =>
                  setConstructorsValue(value, index, "engineManufacturerId")
                }
              >
                {engineManufacturers.map((engineManufacturer) => (
                  <MenuItem
                    key={engineManufacturer.id}
                    value={engineManufacturer.id}
                  >
                    {engineManufacturer.name}
                  </MenuItem>
                ))}
              </SelectGroup>
            </Grid>

            <Grid item xs={12}>
              <SelectGroup
                id={`constructor-legacy-${index}`}
                label='Constructors legacy'
                value={constructor.constructorLegacyId}
                gridSize={12}
                onChange={(value) =>
                  setConstructorsValue(value, index, "constructorLegacyId")
                }
              >
                {legacyConstructors.map((constructor) => (
                  <MenuItem key={constructor.id} value={constructor.id}>
                    {constructor.name}
                  </MenuItem>
                ))}
              </SelectGroup>
            </Grid>
          </React.Fragment>
        ))}
      </FormContainer>
    </Grid>
  )
}
