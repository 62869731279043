import { Button, Grid, MenuItem } from "@mui/material"

import InputGroup from "components/Shared/InputGroup"
import SelectGroup from "components/Shared/SelectGroup"

export default function AddConstructor({
  index,
  name,
  country,
  countries,
  setValue,
  handleRemoveLine,
}) {
  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      {handleRemoveLine && (
        <Grid container justifyContent='flex-end'>
          <Grid item key={index}>
            <Button
              variant={"outlined"}
              color={"error"}
              onClick={handleRemoveLine}
              style={{ width: "100%" }}
            >
              Remove line
            </Button>
          </Grid>
        </Grid>
      )}

      <Grid item xs={6}>
        <InputGroup
          id={`constructor-manufacture-name-${index}`}
          label='Name'
          autocomplete={false}
          value={name}
          onChange={(value) => setValue("name", value, index)}
        />
      </Grid>

      <Grid item xs={6}>
        <SelectGroup
          id={`constructor-manufacturer-country-${index}`}
          label='Country'
          value={country}
          onChange={(value) => setValue("countryId", value, index)}
        >
          {countries.map((country) => (
            <MenuItem key={country.id} value={country.id}>
              {country.name}
            </MenuItem>
          ))}
        </SelectGroup>
      </Grid>
    </Grid>
  )
}
