import {
  Button,
  Grid,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material"

import Navigation from "components/Navigation/Index"
import { useNavigate } from "react-router-dom"

export default function ListPageContainer({
  title,
  buttonText,
  handleButton,
  totalPages,
  currentPage,
  paginationLink,
  pageSize,
  navs = [],
  children,
}) {
  const navigate = useNavigate()
  return (
    <Grid container spacing={4} paddingY={4}>
      <Grid item xs={6}>
        <Typography variant='h4' component='h1'>
          {title}
        </Typography>
      </Grid>

      {handleButton && (
        <Grid
          item
          xs={6}
          display='flex'
          justifyContent='flex-end'
          alignItems='center'
          alignSelf='stretch'
        >
          <Button variant='contained' onClick={handleButton}>
            {buttonText}
          </Button>
        </Grid>
      )}

      {navs?.length > 0 && (
        <Grid item xs={12}>
          <Navigation navs={navs} />
        </Grid>
      )}

      <Grid item xs={12}>
        {children}
      </Grid>

      {totalPages && (
        <Grid container paddingX={4} paddingY={4} spacing={1}>
          <Grid
            item
            xs={10}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(event, value) => {
                navigate(
                  `/${paginationLink}?pageNumber=${value}&pageSize=${pageSize}`
                )
              }}
              variant='outlined'
            />
          </Grid>
          <Grid item xs={2}>
            <Select
              fullWidth
              id={"pageSize"}
              label={"Page size"}
              value={pageSize}
              onChange={(event) =>
                navigate(
                  `/${paginationLink}?pageNumber=1&pageSize=${event.target.value}`
                )
              }
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
