import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"

import ActionTableCell from "components/Shared/ActionTableCell"
import NoDataToDisplay from "components/Shared/NoDataToDisplay"
import { useNavigate } from "react-router-dom"

export default function ConstructorManufacturersList({ constructors }) {
  const navigate = useNavigate()

  if (constructors.length === 0) {
    return <NoDataToDisplay />
  }

  return (
    <TableContainer sx={{ maxHeight: 900 }}>
      <Table stickyHeader aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell component='th'>Id</TableCell>
            <TableCell component='th'>Name</TableCell>
            <TableCell component='th'>Country</TableCell>
            <TableCell component='th'>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {constructors.map((c) => (
            <TableRow key={c.id}>
              <TableCell component='td'>{c.id}</TableCell>
              <TableCell component='td'>{c.name}</TableCell>
              <TableCell component='td'>{c.country.alphaThreeCode}</TableCell>
              <TableCell component='td'>
                <ActionTableCell
                  actions={[
                    {
                      variant: "outlined",
                      title: "Edit",
                      onClick: () =>
                        navigate(`/constructor-manufacturers/${c.id}/edit`),
                    },
                  ]}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
