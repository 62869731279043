import { CircularProgress, Grid, MenuItem, Typography } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"

import FormContainer from "components/Shared/FormContainer"
import InputGroup from "components/Shared/InputGroup"
import React from "react"
import SelectGroup from "components/Shared/SelectGroup"
import useAllConstructorManufacturers from "core/hooks/useAllConstructorManufacturers"
import useCountries from "core/hooks/useCountries"
import useEngineManufacturer from "core/hooks/useEngineManufacturer"

export default function EngineManufacturerEditPage() {
  const params = useParams()

  const { countries } = useCountries()
  const { constructorManufacturers: constructors } =
    useAllConstructorManufacturers()

  const {
    engineManufacturer,
    isLoading,
    updateEngineManufacturer,
    deleteEngineManufacturer,
  } = useEngineManufacturer(params.engineManufacturerId)

  const navigate = useNavigate()

  const [name, setName] = React.useState("")
  const [countryId, setCountryId] = React.useState("")
  const [constructorManufacturerId, setConstructorManufacturerId] =
    React.useState("")

  React.useEffect(() => {
    if (engineManufacturer != null) {
      setName(engineManufacturer.name)
      setCountryId(engineManufacturer.country.id)
      setConstructorManufacturerId(
        engineManufacturer.constructorManufacturer?.id
      )
    }
  }, [engineManufacturer])

  const handleDelete = async (event) => {
    event.preventDefault()
    await deleteEngineManufacturer(engineManufacturer.id)
    navigate("/engine-manufacturers")
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const result = await updateEngineManufacturer(
      name,
      countryId,
      constructorManufacturerId
    )

    if (result.status === 204) {
      navigate("/engine-manufacturers")
      return
    }
  }

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <Grid container gap={2}>
      <Typography variant='h4' component='h1'>
        Edit engine manufacturer {engineManufacturer.name}
      </Typography>

      <FormContainer
        handleSubmit={handleDelete}
        buttonText='Delete engine manufacturer'
        buttonColor='error'
        justifyContent='flex-end'
      />

      <FormContainer handleSubmit={handleSubmit}>
        <Grid item xs={12}>
          <InputGroup
            id='id'
            label='Id'
            value={engineManufacturer.id}
            disabled
          />
        </Grid>

        <Grid item xs={12}>
          <InputGroup
            id='engine-manufacturer-name'
            label='Name'
            value={name}
            onChange={(value) => setName(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectGroup
            id='engine-manufacturer-constructor-id'
            label='Constructors'
            value={constructorManufacturerId}
            onChange={(value) => setConstructorManufacturerId(value)}
          >
            <MenuItem value={null} style={{ padding: "16px" }}>
              {" "}
            </MenuItem>
            {constructors.map((constructor) => (
              <MenuItem key={constructor.id} value={constructor.id}>
                {constructor.name}
              </MenuItem>
            ))}
          </SelectGroup>
        </Grid>

        <Grid item xs={12}>
          <SelectGroup
            id='engine-manufacturer-country'
            label='Country'
            value={countryId}
            onChange={(value) => setCountryId(value)}
          >
            {countries.map((country) => (
              <MenuItem key={country.id} value={country.id}>
                {country.name}
              </MenuItem>
            ))}
          </SelectGroup>
        </Grid>
      </FormContainer>
    </Grid>
  )
}
