import React from 'react'
import { Container, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import ActionTableCell from 'components/Shared/ActionTableCell'
import { useNavigate } from 'react-router-dom'

export default function Seasons({ seasons }) {
  const navigate = useNavigate()
  return (
    <Container>
      <Table stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Year</TableCell>
            <TableCell>Rounds number</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {seasons.map((season) => (
            <TableRow key={season.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {season.year}
              </TableCell>
              <TableCell component="th" scope="row">
                {season.round}
              </TableCell>
              <TableCell component="th" scope="row">
                {season.status}
              </TableCell>
              <TableCell component="th" scope="row">
                <ActionTableCell
                  actions={[
                    {
                      variant: 'outlined',
                      title: 'Edit',
                      onClick: () => navigate(`/seasons/${season.id}/edit`),
                    },
                  ]}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  )
}
