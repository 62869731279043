import { grandsPrix } from "core/api/grandsPrix"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "react-query"

export default function useRace(gpId, raceId) {
  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const queryResponse = useQuery(["races", { gpId, raceId }], () =>
    getToken().then((token) => {
      return grandsPrix.getRaceById(gpId, raceId, token)
    })
  )

  const updateRaceParticipations = async (body) => {
    const token = await getToken()
    return grandsPrix.updateRace(gpId, raceId, body, token)
  }

  return {
    ...queryResponse,
    race: queryResponse.data,
    updateRaceParticipations,
  }
}
