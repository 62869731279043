import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"

import ActionTableCell from "components/Shared/ActionTableCell"
import DisplayGroup from "components/Shared/DisplayGroup"
import NoDataToDisplay from "components/Shared/NoDataToDisplay"
import { useNavigate } from "react-router-dom"

export default function EngineManufacturersList({ engineManufacturers }) {
  const navigate = useNavigate()

  if (engineManufacturers.length === 0) {
    return <NoDataToDisplay />
  }

  return (
    <TableContainer sx={{ maxHeight: 900 }}>
      <Table stickyHeader aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell component='th'>Id</TableCell>
            <TableCell component='th'>Name</TableCell>
            <TableCell component='th'>Constructor</TableCell>
            <TableCell component='th'>Country</TableCell>
            <TableCell component='th'>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {engineManufacturers.map((c) => {
            return (
              <TableRow key={c.id}>
                <TableCell component='td'>{c.id}</TableCell>
                <TableCell component='td'>{c.name}</TableCell>
                <TableCell component='td'>
                  {c.constructorManufacturer && (
                    <DisplayGroup
                      groups={[
                        { title: "id", value: c.constructorManufacturer.id },
                        {
                          title: "name",
                          value: c.constructorManufacturer.name,
                        },
                      ]}
                    />
                  )}
                </TableCell>
                <TableCell component='td'>{c.country.alphaThreeCode}</TableCell>
                <TableCell component='td'>
                  <ActionTableCell
                    actions={[
                      {
                        variant: "outlined",
                        title: "Edit",
                        onClick: () =>
                          navigate(`/engine-manufacturers/${c.id}/edit`),
                      },
                    ]}
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
