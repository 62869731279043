export default async function responseToJson(response) {
  let data
  try {
    if (
      response.status.toString().startsWith("4") ||
      response.status.toString().startsWith("5")
    ) {
      return data
    }

    data = await response.json()
  } catch (error) {
    data = null
  }

  return data
}
