import AddConstructor from "components/AddConstructor"
import { CircularProgress } from "@mui/material"
import FormPageContainer from "components/Shared/FormPageContainer"
import React from "react"
import useConstructorManufacturers from "core/hooks/useConstructorManufacturers"
import useCountries from "core/hooks/useCountries"
import { useNavigate } from "react-router-dom"

export default function ConstructorManufacturersAddPage() {
  const { addConstructorManufacturers } = useConstructorManufacturers()
  const { countries, isLoading: isLoadingCountries } = useCountries()
  const navigate = useNavigate()
  const [constructors, setConstructors] = React.useState([])
  const [isError, setIsError] = React.useState(false)

  React.useEffect(() => {
    if (countries != null && countries.length > 0) {
      setConstructors([
        {
          name: "",
          countryId: countries[0].id,
        },
      ])
    }
  }, [countries])

  if (isLoadingCountries) {
    return <CircularProgress />
  }

  const handleAddConstructor = () => {
    setConstructors((c) => [
      ...c,
      {
        name: "",
        countryId: countries[0].id,
      },
    ])
  }

  const setValue = (property, value, index) => {
    isError && setIsError(false)
    const constructorsUpdated = constructors.map((c, i) => {
      if (i === index) {
        c[property] = value
      }
      return c
    })

    setConstructors(constructorsUpdated)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const result = await addConstructorManufacturers({ constructors })

    if (result.status === 204) {
      navigate("/constructor-manufacturers")
      return
    }
  }

  const handleRemoveLine = (index) => {
    if (constructors.length === 1) {
      return
    }

    const constructorsUpdated = constructors.filter((_, i) => i !== index)
    setConstructors(constructorsUpdated)
  }

  return (
    <FormPageContainer
      buttonText={"Add constructor"}
      handleButton={handleAddConstructor}
      isError={isError}
      handleSubmit={handleSubmit}
      title={"Create new constructors"}
    >
      {constructors.map((constructor, index) => (
        <AddConstructor
          key={index}
          index={index}
          name={constructor.name}
          country={constructor.countryId}
          countries={countries}
          setValue={setValue}
          handleRemoveLine={
            constructors.length > 1 ? () => handleRemoveLine(index) : null
          }
        />
      ))}
    </FormPageContainer>
  )
}
