import GrandsPrix from "components/GrandsPrix"
import ListPageContainer from "components/Shared/ListPageContainer"
import LoadingProgress from "components/Shared/LoadingProgress"
import React from "react"
import useGrandsPrix from "core/hooks/useGrandsPrix"
import { useLocation } from "react-router-dom"

export default function GrandsPrixPage() {
  const location = useLocation()

  const pageNumber = new URLSearchParams(location.search).get("pageNumber")
  const { grandsPrix, isLoading, totalPages } = useGrandsPrix(pageNumber ?? 1)

  if (isLoading) {
    return <LoadingProgress />
  }

  return (
    <ListPageContainer
      title={"List of grands prix"}
      totalPages={totalPages}
      currentPage={pageNumber == null ? 1 : parseInt(pageNumber)}
      paginationLink={"grands-prix"}
    >
      <GrandsPrix grandsPrix={grandsPrix} />
    </ListPageContainer>
  )
}
