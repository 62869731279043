import { Button, Grid, MenuItem } from "@mui/material"

import InputGroup from "components/Shared/InputGroup"
import React from "react"
import SelectGroup from "components/Shared/SelectGroup"

export default function AddTeamHistory({
  index,
  fullName,
  shortName,
  color,
  country,
  countries,
  legacyConstructors,
  constructorManufacturers,
  engineManufacturers,
  constructors,
  seasons,
  seasonId,
  setValue,
  setConstructorsValue,
  handleRemoveLine,
  handleAddConstructor,
  handleRemoveConstructor,
}) {
  return (
    <Grid container rowSpacing={2}>
      {handleRemoveLine && (
        <Grid
          item
          key={index}
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant={"outlined"}
            color={"error"}
            onClick={handleRemoveLine}
          >
            Remove history
          </Button>
        </Grid>
      )}

      <Grid item xs={12}>
        <SelectGroup
          id={`team-seasons-${index}`}
          label='Seasons'
          value={seasonId}
          gridSize={6}
          onChange={(value) => setValue("seasonId", value, index)}
        >
          {seasons.map((season) => (
            <MenuItem key={season.id} value={season.id}>
              {season.year}
            </MenuItem>
          ))}
        </SelectGroup>
      </Grid>

      <Grid item xs={12}>
        <InputGroup
          id={`team-full-name-${index}`}
          label='Full name'
          autocomplete={false}
          value={fullName}
          gridSize={6}
          onChange={(value) => setValue("fullName", value, index)}
        />
      </Grid>

      <Grid item xs={12}>
        <InputGroup
          id={`team-short-name-${index}`}
          label='Short name'
          autocomplete={false}
          value={shortName}
          gridSize={6}
          onChange={(value) => setValue("shortName", value, index)}
        />
      </Grid>

      <Grid item xs={12}>
        <InputGroup
          id={`team-color-${index}`}
          label='Color'
          autocomplete={false}
          value={color}
          gridSize={6}
          onChange={(value) => setValue("color", value, index)}
        />
      </Grid>

      <Grid item xs={12}>
        <SelectGroup
          id={`team-country-${index}`}
          label='Country'
          value={country}
          gridSize={6}
          onChange={(value) => setValue("countryId", value, index)}
        >
          {countries.map((country) => (
            <MenuItem key={country.id} value={country.id}>
              {country.name}
            </MenuItem>
          ))}
        </SelectGroup>
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant={"outlined"}
          color={"primary"}
          onClick={handleAddConstructor}
        >
          Add constructor
        </Button>
      </Grid>

      {constructors.map((constructor, constructorIndex) => (
        <React.Fragment key={constructorIndex}>
          {constructors.length > 1 && (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant={"outlined"}
                color={"error"}
                onClick={() => handleRemoveConstructor(index, constructorIndex)}
              >
                Remove constructor
              </Button>
            </Grid>
          )}

          <Grid item xs={12}>
            <SelectGroup
              id={`team-constructor-${constructorIndex}`}
              label='Constructors'
              value={constructor.constructorId}
              gridSize={6}
              onChange={(value) =>
                setConstructorsValue(
                  "constructorId",
                  value,
                  index,
                  constructorIndex
                )
              }
            >
              {constructorManufacturers.map((constructor) => (
                <MenuItem key={constructor.id} value={constructor.id}>
                  {constructor.name}
                </MenuItem>
              ))}
            </SelectGroup>
          </Grid>

          <Grid item xs={12}>
            <SelectGroup
              id={`team-engine-manufacturer-${constructorIndex}`}
              label='Engine manufacturers'
              value={constructor.engineManufacturerId}
              gridSize={6}
              onChange={(value) =>
                setConstructorsValue(
                  "engineManufacturerId",
                  value,
                  index,
                  constructorIndex
                )
              }
            >
              {engineManufacturers.map((engineManufacturer) => (
                <MenuItem
                  key={engineManufacturer.id}
                  value={engineManufacturer.id}
                >
                  {engineManufacturer.name}
                </MenuItem>
              ))}
            </SelectGroup>
          </Grid>

          <Grid item xs={12}>
            <SelectGroup
              id={`team-constructor-legacy-${constructorIndex}`}
              label='Constructors legacy'
              value={constructor.constructorLegacyId}
              gridSize={6}
              onChange={(value) =>
                setConstructorsValue(
                  "constructorLegacyId",
                  value,
                  index,
                  constructorIndex
                )
              }
            >
              {legacyConstructors.map((constructor) => (
                <MenuItem key={constructor.id} value={constructor.id}>
                  {constructor.name}
                </MenuItem>
              ))}
            </SelectGroup>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  )
}
