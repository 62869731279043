import { Button, Grid } from "@mui/material"

export default function ActionTableCell({ actions }) {
  return (
    <Grid container direction={"column"} rowSpacing={0.5}>
      {actions.map((action, index) => {
        return (
          <Grid item key={index} xs={8}>
            <Button
              variant={action.variant}
              color={action.color}
              onClick={action.onClick}
              style={{ width: "100%" }}
            >
              {action.title}
            </Button>
          </Grid>
        )
      })}
    </Grid>
  )
}
