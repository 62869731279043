import { constructors } from "../../api/constructors"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "react-query"

export default function useConstructor(constructorId) {
  const types = [
    { id: 0, value: "Car" },
    { id: 1, value: "LittleLogo" },
  ]

  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const updateConstructor = async (body) => {
    const token = await getToken()
    return constructors.updateConstructor(constructorId, body, token)
  }

  const queryResponse = useQuery(["constructors", { constructorId }], () =>
    getToken().then((token) => {
      return constructors.getConstructorById(constructorId, token)
    })
  )

  return {
    ...queryResponse,
    constructor: queryResponse?.data ?? null,
    types,
    updateConstructor,
  }
}
