import * as React from 'react'

import { Container, Menu, MenuItem } from '@mui/material'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'

export default function Navbar() {
  const { logout } = useAuth0()
  const navigate = useNavigate()

  const [anchorDrivers, setAnchorDrivers] = React.useState(null)
  const openDrivers = Boolean(anchorDrivers)
  const handleDriversClick = (event) => {
    setAnchorDrivers(event.currentTarget)
  }
  const handleDriversClose = (redirection) => {
    setAnchorDrivers(null)
    if (redirection !== null) {
      navigate(redirection)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        textAlign: 'flex-start',
      }}
    >
      <AppBar position="static" padding={0}>
        <Toolbar>
          <Container
            sx={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'flex-start',
            }}
          >
            <Container
              sx={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'flex-start',
              }}
            >
              <Typography variant="h5" sx={{ minWidth: 100, padding: 1 }}>
                BackOffice Hyprace
              </Typography>
              <Button
                variant="secondary"
                id="drivers-button"
                aria-controls={openDrivers ? 'drivers-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openDrivers ? 'true' : undefined}
                onClick={handleDriversClick}
              >
                Drivers
              </Button>
              <Menu
                id="drivers-menu"
                anchorEl={anchorDrivers}
                open={openDrivers}
                onClose={() => {
                  setAnchorDrivers(null)
                }}
                MenuListProps={{
                  'aria-labelledby': 'drivers-button',
                }}
              >
                <MenuItem onClick={() => handleDriversClose('/drivers')}>All</MenuItem>
                <MenuItem onClick={() => handleDriversClose('/drivers-current-season')}>Current Season</MenuItem>
              </Menu>
              <Button
                variant="secondary"
                id="manufacturers-button"
                aria-haspopup="true"
                onClick={() => navigate('manufacturers')}
              >
                Manufacturers
              </Button>
              <Button variant="secondary" id="teams-button" aria-haspopup="true" onClick={() => navigate('teams')}>
                Teams
              </Button>
              <Button
                variant="secondary"
                id="grands-prix-button"
                aria-haspopup="true"
                onClick={() => navigate('grands-prix')}
              >
                Grands Prix
              </Button>
              <Button variant="secondary" id="seasons-button" aria-haspopup="true" onClick={() => navigate('seasons')}>
                Seasons
              </Button>
            </Container>
            <Button
              color="inherit"
              sx={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
              }}
              onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
            >
              Logout
            </Button>
          </Container>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
