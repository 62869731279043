import apiService from "core/services/network/api"
import responseToJson from "core/utils/reponseToJson"

export const grandsPrix = {
  getGrandsPrix: (page, token) => {
    return apiService
      .getData(`v1/grands-prix?pageNumber=${page}&pageSize=50`, token)
      .then(responseToJson)
  },
  getGrandPrixById: (gpId, token) => {
    return apiService
      .getData(`v1/grands-prix/${gpId}`, token)
      .then(responseToJson)
  },
  getRaceById: (gpId, raceId, token) => {
    return apiService
      .getData(`v1/grands-prix/${gpId}/races/${raceId}`, token)
      .then(responseToJson)
  },
  updateGrandPrix: (gpId, body, token) => {
    return apiService.patch(
      `v1/grands-prix/${gpId}`,
      JSON.stringify(body),
      token
    )
  },
  updateRace: (gpId, raceId, body, token) => {
    return apiService.patch(
      `v1/grands-prix/${gpId}/races/${raceId}/participations`,
      JSON.stringify(body),
      token
    )
  },
}
