import { Container, FormControl, Grid, TextField, Typography } from '@mui/material'
import Navigation from 'components/Navigation/Index'
import LoadingProgress from 'components/Shared/LoadingProgress'
import useSeason from 'core/hooks/useSeason/useSeason'
import React from 'react'
import { useParams } from 'react-router-dom'
import SeasonDrivers from 'components/SeasonDrivers'

export default function SeasonEditPage() {
  const params = useParams()
  const seasonId = params.seasonId

  const { season } = useSeason(seasonId)

  if (!season) {
    return <LoadingProgress></LoadingProgress>
  }

  return (
    <Container>
      <Grid
        container
        columnSpacing={2}
        paddingRight={6}
        paddingLeft={6}
        paddingBottom={2}
        paddingTop={2}
        rowSpacing={2}
      >
        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h2">Edit season</Typography>
        </Grid>
        <Grid item xs={12}>
          <Navigation navs={[{ link: `/seasons/`, title: 'Seasons' }]} />
        </Grid>
        {/* <Grid item xs={12}>
          {successDisplay && (
            <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
              This is a success Alert.
            </Alert>
          )}
          {errorDisplay && <Alert severity="error">This is a error Alert.</Alert>}
        </Grid> */}
      </Grid>

      <FormControl fullWidth>
        <Grid
          container
          columnSpacing={2}
          paddingRight={6}
          paddingLeft={6}
          paddingBottom={2}
          paddingTop={2}
          rowSpacing={2}
        >
          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
            <TextField id="seasonId" label="Id" defaultValue={season.id} fullWidth disabled />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
            <TextField id="seasonYear" label="Year" defaultValue={season.year} fullWidth disabled />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
            <TextField id="seasonRound" label="Round" defaultValue={season.round} fullWidth disabled />
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
            <TextField id="seasonStatus" label="Status" defaultValue={season.status} fullWidth disabled />
          </Grid>
        </Grid>
      </FormControl>
      <SeasonDrivers seasonId={season.id} seasonDrivers={season.drivers}></SeasonDrivers>
    </Container>
  )
}
