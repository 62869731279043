import { CircularProgress, Container, Grid } from "@mui/material";

export default function LoadingProgress() {
  return (
    <Container>
      <Grid
        container
        paddingRight={6}
        paddingLeft={6}
        paddingBottom={2}
        paddingTop={2}
        rowSpacing={5}
      >
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Grid>
      </Grid>
    </Container>
  );
}
