import * as React from 'react'
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
} from '@mui/material'

import ActionTableCell from 'components/Shared/ActionTableCell'
import { useNavigate } from 'react-router-dom'
import SeasonDriverConstructor from 'components/SeasonDriverConstructor'

export default function SeasonDrivers({ seasonId, seasonDrivers }) {
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Container>
      <Table stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>First name</TableCell>
            <TableCell>Last name</TableCell>
            <TableCell>Constructors</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {seasonDrivers?.map((driver) => (
            <TableRow key={driver.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {driver.firstName}
              </TableCell>
              <TableCell component="th" scope="row">
                {driver.lastName}
              </TableCell>
              <TableCell>
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Driver Status</TableCell>
                      <TableCell>Driving Level</TableCell>
                      <TableCell>Edit driverstatus</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {driver.constructors?.map((constructor) => (
                      <SeasonDriverConstructor seasonId={seasonId} driver={driver} constructor={constructor} />
                    ))}
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  )
}
