import { useQuery, useQueryClient } from "react-query"

import { manufacturers } from "core/api/manufacturers"
import { useAuth0 } from "@auth0/auth0-react"

export default function useEngineManufacturer(engineManufacturerId) {
  const queryClient = useQueryClient()
  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const queryResponse = useQuery(
    [
      "constructor-manufacturer",
      { engineManufacturerId: engineManufacturerId },
    ],
    () =>
      getToken().then((token) => {
        return manufacturers.getEngineManufacturersById(
          engineManufacturerId,
          token
        )
      })
  )

  const updateEngineManufacturer = async (
    name,
    countryId,
    constructorManufacturerId
  ) => {
    const token = await getToken()
    return manufacturers
      .updateEngineManufacturers(
        engineManufacturerId,
        {
          id: engineManufacturerId,
          engineManufacturer: {
            name,
            countryId,
            constructorManufacturerId,
          },
        },
        token
      )
      .then((response) => {
        queryClient.removeQueries("engine-manufacturers")
        queryClient.removeQueries("all-engine-manufacturers")
        return response
      })
  }

  const deleteEngineManufacturer = async () => {
    const token = await getToken()
    return manufacturers
      .deleteEngineManufacturers(engineManufacturerId, token)
      .then((response) => {
        queryClient.removeQueries("engine-manufacturers")
        queryClient.removeQueries("all-engine-manufacturers")
        return response
      })
  }

  return {
    ...queryResponse,
    engineManufacturer: queryResponse?.data ?? null,
    updateEngineManufacturer,
    deleteEngineManufacturer,
  }
}
