import { MenuItem, Select, TableCell, TableRow, TextField } from "@mui/material"

export default function RaceParticipationEdit({
  participation,
  updateParticipation,
  setSuccessDisplay,
  setErrorDisplay,
}) {
  const displayResultTime = () => {
    if (participation.position === 1) {
      return participation.result.time
    }

    if (
      participation.result.time == null &&
      participation.result.lapsBehindLeader == null
    ) {
      return
    }

    if (participation.result.time == null) {
      return `+${participation.result.lapsBehindLeader} laps`
    }

    return `+${participation.result.time}s`
  }

  return (
    <TableRow
      key={participation.id}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component='td' scope='row'>
        <TextField
          id={`position-${participation.id}`}
              label='Pos'
              defaultValue={participation.result.position}
              fullWidth
              onChange={(event) => {
                setSuccessDisplay(false)
                setErrorDisplay(false)
                 updateParticipation(
                  participation.id,
                  "result.position",
                  event.target.value
                )
              }}
            />
      </TableCell>
      <TableCell component='td' scope='row'>
         <TextField
          id={`finishedPosition-${participation.id}`}
              label='FS Pos'
              defaultValue={participation.result.finishedPosition}
              fullWidth
              onChange={(event) => {
                setSuccessDisplay(false)
                setErrorDisplay(false)
                 updateParticipation(
                  participation.id,
                  "result.finishedPosition",
                  event.target.value
                )
              }}
            />
      </TableCell>
      <TableCell component='td' scope='row'>
        {participation.result.grid}
      </TableCell>
      <TableCell component='td' scope='row'>
        {participation.result.laps}
      </TableCell>
      <TableCell component='td' scope='row'>
        {displayResultTime()}
      </TableCell>
      <TableCell component='td' scope='row'>
        {participation.result.points}
      </TableCell>
      <TableCell component='td' scope='row'>
        <Select
          id={`result-status-${participation.id}`}
          value={participation.result.resultStatus.status}
          onChange={(event) => {
            setSuccessDisplay(false)
            setErrorDisplay(false)
            updateParticipation(
              participation.id,
              "result.resultStatus.status",
              event.target.value
            )
          }}
        >
          <MenuItem value={"Ok"}>Ok</MenuItem>
          <MenuItem value={"Dnf"}>Dnf</MenuItem>
          <MenuItem value={"Dns"}>Dns</MenuItem>
          <MenuItem value={"Dsq"}>Dsq</MenuItem>
        </Select>
      </TableCell>
      <TableCell component='td' scope='row'>
        {`${participation.driver.firstName} ${participation.driver.lastName}`}
      </TableCell>
      <TableCell component='td' scope='row'>
        {participation.constructor.name}
      </TableCell>
    </TableRow>
  )
}
