export class ApiService {
  constructor() {
    this.apiUrl = process.env.REACT_APP_Bff_URL
  }

  getData(route, token = null) {
    return fetch(this.createCompleteRoute(this.apiUrl, route), {
      method: "GET",
      headers: this.generateHeaders(token),
    })
  }

  headData(route, token = null) {
    return fetch(this.createCompleteRoute(this.apiUrl, route), {
      method: "HEAD",
      headers: this.generateHeaders(token),
    })
  }

  create(route, body, token = null) {
    return fetch(this.createCompleteRoute(this.apiUrl, route), {
      method: "POST",
      headers: this.generateHeaders(token),
      body: body,
    })
  }

  update(route, body, token = null) {
    return fetch(this.createCompleteRoute(this.apiUrl, route), {
      method: "PUT",
      headers: this.generateHeaders(token),
      body: body,
    })
  }

  patch(route, body, token = null) {
    return fetch(this.createCompleteRoute(this.apiUrl, route), {
      method: "PATCH",
      headers: this.generateHeaders(token),
      body: body,
    })
  }

  delete(route, body, token = null) {
    return fetch(this.createCompleteRoute(this.apiUrl, route), {
      method: "DELETE",
      headers: this.generateHeaders(token),
      body: body,
    })
  }

  createCompleteRoute(envAddress, route) {
    return `${envAddress}/${route}`
  }

  generateHeaders(token) {
    return {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  }
}

var apiService = new ApiService()
export default apiService
