import { useQuery, useQueryClient } from "react-query"

import { manufacturers } from "core/api/manufacturers"
import { useAuth0 } from "@auth0/auth0-react"

export default function useEngineManufacturers(page = 1, pageSize = 100) {
  const queryClient = useQueryClient()
  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const queryResponse = useQuery(
    ["engine-manufacturers", { page, pageSize }],
    () =>
      getToken().then((token) => {
        return manufacturers.getEngineManufacturers(page, pageSize, token)
      })
  )

  const addEngineManufacturers = async (body) => {
    const token = await getToken()
    return manufacturers
      .addEngineManufacturers(body, token)
      .then((response) => {
        queryClient.removeQueries("engine-manufacturers")
        queryClient.removeQueries("all-engine-manufacturers")
        return response
      })
  }

  return {
    ...queryResponse,
    engineManufacturers: queryResponse?.data?.items ?? [],
    totalPages: queryResponse?.data?.totalPages,
    currentPage: queryResponse?.data?.currentPage,
    totalCount: queryResponse?.data?.totalCount,
    addEngineManufacturers,
  }
}
