import {
  Alert,
  Button,
  Container,
  FormControl,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"

import { CheckCircleOutline } from "@mui/icons-material"
import LoadingProgress from "components/Shared/LoadingProgress"
import Navigation from "components/Navigation/Index"
import RaceParticipationEdit from "components/RaceParticipationEdit"
import React from "react"
import { set } from "core/utils/setValue"
import { useParams } from "react-router-dom"
import useRace from "core/hooks/useRace"

export default function RaceEditPage() {
  const params = useParams()
  const gpId = params.gpId
  const raceId = params.raceId

  const [successDisplay, setSuccessDisplay] = React.useState(false)
  const [errorDisplay, setErrorDisplay] = React.useState(false)
  const [participations, setParticipations] = React.useState([])

  const { race, refetch, isLoading, updateRaceParticipations } = useRace(
    gpId,
    raceId
  )

  React.useEffect(() => {
    if (race != null) {
      setParticipations(race.participations)
    }
  }, [race])

  const updateParticipation = (participationId, field, value) => {
    const participationsUpdated = participations.map((p) => {
      if (p.id === participationId) {
        set(p, field, value)
      }
      return p
    })

    setParticipations(participationsUpdated)
  }

  const handleSubmit = () => {
    updateRaceParticipations({
      participations,
    }).then((response) => {
      if (response.ok === true) {
        setSuccessDisplay(true)
        refetch()
      } else {
        setErrorDisplay(true)
      }
    })
  }

  if (isLoading) {
    return <LoadingProgress />
  }

  return (
    <Container>
      <Grid
        container
        columnSpacing={2}
        paddingRight={6}
        paddingLeft={6}
        paddingBottom={2}
        paddingTop={2}
        rowSpacing={2}
      >
        <Grid
          item
          xs={12}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <Typography variant='h2'>Edit Race</Typography>
        </Grid>
        <Grid item xs={12}>
          <Navigation
            navs={[
              { link: `/grands-prix`, title: "Grands Prix" },
              { link: `/grands-prix/${gpId}/edit`, title: gpId },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          {successDisplay && (
            <Alert
              icon={<CheckCircleOutline fontSize='inherit' />}
              severity='success'
            >
              This is a success Alert.
            </Alert>
          )}
          {errorDisplay && (
            <Alert severity='error'>This is a error Alert.</Alert>
          )}
        </Grid>
      </Grid>

      <FormControl fullWidth>
        <Grid
          container
          columnSpacing={2}
          paddingRight={6}
          paddingLeft={6}
          paddingBottom={2}
          paddingTop={2}
          rowSpacing={2}
        >
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='gpId'
              label='gp id'
              defaultValue={race.grandPrixId}
              fullWidth
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='raceId'
              label='Id'
              defaultValue={race.id}
              fullWidth
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='raceType'
              label='Type'
              defaultValue={race.type}
              fullWidth
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='raceStartDate'
              label='Start Date'
              defaultValue={race.date}
              fullWidth
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='raceEndDate'
              label=' End Date'
              defaultValue={race.endDate}
              fullWidth
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
            alignSelf='stretch'
          >
            <TableContainer component={Paper}>
              <Table stickyHeader aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Pos</TableCell>
                    <TableCell>Fs Pos</TableCell>
                    <TableCell>Grid</TableCell>
                    <TableCell>Laps</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Points</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>DriverId</TableCell>
                    <TableCell>ConstructorId</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {race.participations.map((participation) => (
                    <RaceParticipationEdit
                      key={participation.id}
                      participation={participation}
                      updateParticipation={updateParticipation}
                      setErrorDisplay={setErrorDisplay}
                      setSuccessDisplay={setSuccessDisplay}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
            alignSelf='stretch'
          >
            <Button size='medium' variant='contained' onClick={handleSubmit}>
              Save
            </Button>
          </Grid>
        </Grid>
      </FormControl>
    </Container>
  )
}
