import apiService from "core/services/network/api"
import responseToJson from "core/utils/reponseToJson"

export const manufacturers = {
  getConstructorManufacturers: (page, pageSize, token) => {
    return apiService
      .getData(
        `v1/constructor-manufacturers?pageNumber=${page}&pageSize=${pageSize}&sortBy=asc(Name)`,
        token
      )
      .then(responseToJson)
  },
  getAllConstructorManufacturers: (token) => {
    return apiService
      .getData(`v1/constructor-manufacturers?sortBy=asc(Name)&all=true`, token)
      .then(responseToJson)
  },
  addConstructorManufacturers: (body, token) => {
    return apiService.create(
      `v1/constructor-manufacturers`,
      JSON.stringify(body),
      token
    )
  },
  updateConstructorManufacturers: (id, body, token) => {
    return apiService.patch(
      `v1/constructor-manufacturers/${id}`,
      JSON.stringify(body),
      token
    )
  },
  deleteConstructorManufacturers: (id, token) => {
    return apiService.delete(`v1/constructor-manufacturers/${id}`, token)
  },
  getConstructorManufacturersById: (id, token) => {
    return apiService
      .getData(`v1/constructor-manufacturers/${id}`, token)
      .then(responseToJson)
  },
  getEngineManufacturers: (page, pageSize, token) => {
    return apiService
      .getData(
        `v1/engine-manufacturers?pageNumber=${page}&pageSize=${pageSize}&sortBy=asc(Name)`,
        token
      )
      .then(responseToJson)
  },
  getAllEngineManufacturers: (token) => {
    return apiService
      .getData(`v1/engine-manufacturers?sortBy=asc(Name)&all=true`, token)
      .then(responseToJson)
  },
  addEngineManufacturers: (body, token) => {
    return apiService.create(
      `v1/engine-manufacturers`,
      JSON.stringify(body),
      token
    )
  },
  updateEngineManufacturers: (id, body, token) => {
    return apiService.patch(
      `v1/engine-manufacturers/${id}`,
      JSON.stringify(body),
      token
    )
  },
  deleteEngineManufacturers: (id, token) => {
    return apiService.delete(`v1/engine-manufacturers/${id}`, token)
  },
  getEngineManufacturersById: (id, token) => {
    return apiService
      .getData(`v1/engine-manufacturers/${id}`, token)
      .then(responseToJson)
  },
}
