import { countries } from "core/api/countries"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "react-query"

export default function useCountries() {
  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const queryResponse = useQuery(["countries"], () =>
    getToken().then((token) => {
      return countries.getCountries(token)
    })
  )

  return {
    ...queryResponse,
    countries: queryResponse?.data?.countries ?? [],
  }
}
