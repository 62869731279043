import { Button } from "@mui/material";
import Card from "../Shared/Card";
import CardBody from "../Shared/CardBody";
import CardFooter from "../Shared/CardFooter";
import KeyValue from "../Shared/KeyValue";
import { useNavigate } from "react-router-dom";

export default function DriverHistoryCard({
  driverId,
  country,
  constructors,
  firstName,
  id,
  lastName,
  pictures,
  season,
  standing,
}) {
  const navigate = useNavigate();

  return (
    <Card>
      <CardBody>
        <KeyValue title="Id" values={[{ value: id }]} />
        <KeyValue title="DriverId" values={[{ value: driverId }]} />
        <KeyValue title="FirstName" values={[{ value: firstName }]} />
        <KeyValue title="LastName" values={[{ value: lastName }]} />
        <KeyValue
          title="Season"
          values={[{ value: season.id }, { value: season.year }]}
        />
        <KeyValue title="Country" values={[{ value: country.name }]} />
        <KeyValue
          title="Constructors"
          values={constructors.map((constructor) => {
            return {
              value: `${constructor.name} ${constructor.id}`,
            };
          })}
        />
        <KeyValue
          title="Standing"
          values={[
            { value: standing?.isChampion },
            { value: standing?.stadingPoints },
            { value: standing?.standingPosition },
          ]}
        />
        <KeyValue
          title="Images"
          values={pictures.map((picture) => {
            return {
              value: picture.pictureType,
              link: picture.picture,
              isImage: true,
            };
          })}
        />
      </CardBody>
      <CardFooter>
        <Button
          size="medium"
          variant="contained"
          onClick={() => navigate(`/drivers/${driverId}/histories/${id}/edit`)}
        >
          Modifier l'historique
        </Button>
      </CardFooter>
    </Card>
  );
}
