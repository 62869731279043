import { seasons } from 'core/api/seasons'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from 'react-query'

export default function useSeason(seasonId) {
  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const queryResponse = useQuery(['season', { seasonId }], () =>
    getToken().then((token) => {
      return seasons.getSeasonById(seasonId, token)
    })
  )

  const updateDriverStatus = async (driverId, body) => {
    const token = await getToken()
    return seasons.updateDriverStatus(seasonId, driverId, body, token)
  }

  return {
    ...queryResponse,
    season: queryResponse.data,
    updateDriverStatus: updateDriverStatus,
  }
}
