import Constructors from "components/Constructors";
import LoadingProgress from "components/Shared/LoadingProgress";
import useConstructors from "core/hooks/useConstructors";

export default function ConstructorsCurrentSeasonPage() {
  const page = 1;
  const { constructors, isLoading, totalCount } = useConstructors(page, true);

  if (isLoading) {
    return <LoadingProgress />;
  }

  return <Constructors constructors={constructors} totalCount={totalCount} />;
}
