import { useQuery, useQueryClient } from "react-query"

import { teams } from "core/api/teams"
import { useAuth0 } from "@auth0/auth0-react"

export default function useTeam(teamId) {
  const queryClient = useQueryClient()
  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const queryResponse = useQuery(["teams", { teamId }], () =>
    getToken().then((token) => {
      return teams.getTeamById(teamId, token)
    })
  )

  const updateTeam = async (body) => {
    const token = await getToken()
    return teams
      .updateTeam(
        teamId,
        {
          id: teamId,
          team: body,
        },
        token
      )
      .then((response) => {
        queryClient.removeQueries("teams")
        return response
      })
  }

  const deleteTeam = async () => {
    const token = await getToken()
    return teams.deleteTeam(teamId, token).then((response) => {
      queryClient.removeQueries("teams")
      return response
    })
  }

  return {
    ...queryResponse,
    team: queryResponse?.data ?? null,
    updateTeam,
    deleteTeam,
  }
}
