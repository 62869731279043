import { manufacturers } from "core/api/manufacturers"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "react-query"

export default function useAllEngineManufacturers() {
  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const queryResponse = useQuery(["all-engine-manufacturers"], () =>
    getToken().then((token) => {
      return manufacturers.getAllEngineManufacturers(token)
    })
  )

  return {
    ...queryResponse,
    engineManufacturers: queryResponse?.data?.items ?? [],
  }
}
