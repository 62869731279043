import Drivers from "components/Drivers";
import LoadingProgress from "components/Shared/LoadingProgress";
import useDrivers from "core/hooks/useDrivers";

export default function DriversCurrentSeasonPage() {
  const page = 1;
  const { drivers, isLoading, totalCount } = useDrivers(page, true);

  if (isLoading) {
    return <LoadingProgress />;
  }

  return <Drivers drivers={drivers} totalCount={totalCount} />;
}
