import ListPageContainer from 'components/Shared/ListPageContainer'
import LoadingProgress from 'components/Shared/LoadingProgress'
import React from 'react'
import useSeasons from 'core/hooks/useSeasons'
import Seasons from 'components/Seasons'
import { useLocation } from 'react-router-dom'

export default function SeasonsPage() {
  const location = useLocation()

  const { seasons } = useSeasons()

  if (seasons == null) {
    return <LoadingProgress />
  }

  return (
    <ListPageContainer title={'List of seasons'}>
      <Seasons seasons={seasons} />
    </ListPageContainer>
  )
}
