import {
  Container,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material"

import React from "react"
import { useNavigate } from "react-router-dom"

export default function ManufacturersPage() {
  const navigate = useNavigate()

  return (
    <Container>
      <List>
        <ListItem
          alignItems='flex-start'
          onClick={() => navigate("/constructors")}
          sx={{ cursor: "pointer" }}
        >
          <ListItemText
            primary='Legacy Constructors'
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component='span'
                  variant='body2'
                  color='text.primary'
                >
                  See all the legacy constructors
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider />
        <ListItem
          alignItems='flex-start'
          onClick={() => navigate("/constructor-manufacturers")}
          sx={{ cursor: "pointer" }}
        >
          <ListItemText
            primary='Constructor Manufacturers'
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component='span'
                  variant='body2'
                  color='text.primary'
                >
                  The new constructors manufacturers
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider />
        <ListItem
          alignItems='flex-start'
          onClick={() => navigate("/engine-manufacturers")}
          sx={{ cursor: "pointer" }}
        >
          <ListItemText
            primary='Engines Manufacturers'
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component='span'
                  variant='body2'
                  color='text.primary'
                >
                  The engines manufacturers
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      </List>
    </Container>
  )
}
