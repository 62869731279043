import { Container, Grid, Typography } from "@mui/material";

import ConstructorHistory from "../../components/ConstructorHistoryCard";
import LoadingProgress from "../../components/Shared/LoadingProgress";
import Navigation from "../../components/Navigation/Index";
import React from "react";
import useConstructor from "../../core/hooks/useConstructor";
import useConstructorHistories from "../../core/hooks/useConstructorHistories";
import { useParams } from "react-router-dom";

export default function ConstructorHistoriesPage() {
  const params = useParams();
  const { histories, isLoading } = useConstructorHistories(
    params.constructorId
  );

  const { constructor } = useConstructor(params.constructorId);

  if (isLoading || constructor == null) {
    return <LoadingProgress />;
  }

  return (
    <Container>
      <Grid
        container
        paddingRight={6}
        paddingLeft={6}
        paddingBottom={2}
        paddingTop={2}
        rowSpacing={5}
      >
        <Grid item xs={12}>
          <Typography variant="h2">{constructor.name} - Histories</Typography>
        </Grid>
        <Grid item xs={12}>
          <Navigation
            navs={[
              { link: `/constructors/`, title: "Constructors" },
              {
                link: `/constructors/${params.constructorId}/edit`,
                title: params.constructorId,
              },
            ]}
          />
        </Grid>
        {histories.map((item) => {
          return (
            <Grid key={item.id} item xs={12}>
              <ConstructorHistory
                key={item.id}
                color={item.color}
                constructorId={item.constructorId}
                id={item.id}
                name={item.name}
                fullName={item.fullName}
                shortName={item.shortName}
                country={item.country}
                drivers={item.drivers}
                pictures={item.pictures}
                season={item.season}
                standing={item.standing}
              />
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}
