import AddEngineManufacturer from "components/AddEngineManufacturer"
import { CircularProgress } from "@mui/material"
import FormPageContainer from "components/Shared/FormPageContainer"
import React from "react"
import useAllConstructorManufacturers from "core/hooks/useAllConstructorManufacturers"
import useCountries from "core/hooks/useCountries"
import useEngineManufacturers from "core/hooks/useEngineManufacturers"
import { useNavigate } from "react-router-dom"

export default function EngineManufacturerAddPage() {
  const { addEngineManufacturers } = useEngineManufacturers()

  const { countries, isLoading: isLoadingCountries } = useCountries()
  const { constructorManufacturers, isLoading: isLoadingConstructors } =
    useAllConstructorManufacturers()

  const [engineManufacturers, setEngineManufacturers] = React.useState([])
  const [isError, setIsError] = React.useState(false)

  const navigate = useNavigate()

  React.useEffect(() => {
    if (countries != null && countries.length > 0) {
      setEngineManufacturers([
        {
          name: "",
          constructorManufacturerId: null,
          countryId: countries[0].id,
        },
      ])
    }
  }, [countries])

  if (isLoadingCountries || isLoadingConstructors) {
    return <CircularProgress />
  }

  const handleAddEngineManufacturer = () => {
    setEngineManufacturers((c) => [
      ...c,
      {
        name: "",
        constructorManufacturerId: null,
        countryId: countries[0].id,
      },
    ])
  }

  const setValue = (property, value, index) => {
    isError && setIsError(false)
    const constructorsUpdated = engineManufacturers.map((c, i) => {
      if (i === index) {
        c[property] = value
      }
      return c
    })

    setEngineManufacturers(constructorsUpdated)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const result = await addEngineManufacturers({ engineManufacturers })

    if (result.status === 204) {
      navigate("/engine-manufacturers")
      return
    }

    setIsError(true)
  }

  const handleRemoveLine = (index) => {
    if (engineManufacturers.length === 1) {
      return
    }

    const constructorsUpdated = engineManufacturers.filter(
      (_, i) => i !== index
    )
    setEngineManufacturers(constructorsUpdated)
  }

  return (
    <FormPageContainer
      buttonText={"Add engine manufacturer"}
      handleButton={handleAddEngineManufacturer}
      handleSubmit={handleSubmit}
      isError={isError}
      title={"Create new engine manufacturers"}
    >
      {engineManufacturers.map((engineManufacturer, index) => (
        <AddEngineManufacturer
          key={index}
          index={index}
          name={engineManufacturer.name}
          constructorManufacturerId={
            engineManufacturer.constructorManufacturerId
          }
          constructors={constructorManufacturers}
          country={engineManufacturer.countryId}
          countries={countries}
          setValue={setValue}
          handleRemoveLine={
            engineManufacturers.length > 1
              ? () => handleRemoveLine(index)
              : null
          }
        />
      ))}
    </FormPageContainer>
  )
}
