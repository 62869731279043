import { useQuery, useQueryClient } from "react-query"

import { manufacturers } from "core/api/manufacturers"
import { useAuth0 } from "@auth0/auth0-react"

export default function useConstructorManufacturer(constructorManufacturerId) {
  const queryClient = useQueryClient()
  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const queryResponse = useQuery(
    ["constructor-manufacturer", { constructorManufacturerId }],
    () =>
      getToken().then((token) => {
        return manufacturers.getConstructorManufacturersById(
          constructorManufacturerId,
          token
        )
      })
  )

  const updateConstructorManufacturer = async (name, countryId) => {
    const token = await getToken()
    return manufacturers
      .updateConstructorManufacturers(
        constructorManufacturerId,
        {
          id: constructorManufacturerId,
          constructor: {
            name,
            countryId,
          },
        },
        token
      )
      .then((response) => {
        queryClient.removeQueries("constructor-manufacturers")
        queryClient.removeQueries("all-constructor-manufacturers")
        return response
      })
  }

  const deleteConstructorManufacturer = async () => {
    const token = await getToken()
    return manufacturers
      .deleteConstructorManufacturers(constructorManufacturerId, token)
      .then((response) => {
        queryClient.removeQueries("constructor-manufacturers")
        queryClient.removeQueries("all-constructor-manufacturers")
        return response
      })
  }

  return {
    ...queryResponse,
    constructorManufacturer: queryResponse?.data ?? null,
    updateConstructorManufacturer,
    deleteConstructorManufacturer,
  }
}
