import { drivers } from "../../api/drivers"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "react-query"

export default function useDriver(driverId) {
  const types = [
    { id: 0, value: "PictureFirst" },
    { id: 1, value: "Number" },
  ]
  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const updateDriver = async (body) => {
    const token = await getToken()
    return drivers.updateDriver(driverId, body, token)
  }

  const queryResponse = useQuery(["drivers", { driverId }], () =>
    getToken().then((token) => {
      return drivers.getDriverById(driverId, token)
    })
  )

  return {
    ...queryResponse,
    driver: queryResponse.data,
    types,
    updateDriver,
  }
}
