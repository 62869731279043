import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material"

import ActionTableCell from "components/Shared/ActionTableCell"
import moment from "moment"
import { useNavigate } from "react-router-dom"

export default function GrandsPrix({ grandsPrix }) {
  const navigate = useNavigate()
  return (
    <Container>
      <Table stickyHeader aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Season</TableCell>
            <TableCell>Round</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Official Name</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {grandsPrix.map((gp) => (
            <TableRow
              key={gp.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component='th' scope='row'>
                {gp.season.year}
              </TableCell>
              <TableCell component='th' scope='row'>
                {gp.round}
              </TableCell>
              <TableCell component='th' scope='row'>
                {gp.name}
              </TableCell>
              <TableCell component='th' scope='row'>
                {gp.officialName}
              </TableCell>
              <TableCell component='th' scope='row'>
                {`${moment(gp.startDate).format("LLL")} - 
                ${moment(gp.endDate).format("LT")}`}
              </TableCell>
              <TableCell component='th' scope='row'>
                <ActionTableCell
                  actions={[
                    {
                      variant: "outlined",
                      title: "Edit",
                      onClick: () => navigate(`/grands-prix/${gp.id}/edit`),
                    },
                  ]}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  )
}
