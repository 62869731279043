import * as React from 'react'
import {
  TableCell,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material'

import ActionTableCell from 'components/Shared/ActionTableCell'
import useSeason from 'core/hooks/useSeason/useSeason'

export default function SeasonDriverConstructor({ seasonId, driver, constructor }) {
  const [open, setOpen] = React.useState(false)
  const [driverStatus, setDriverStatus] = React.useState(constructor.driverStatus)
  const [drivingLevel, setDrivingLevel] = React.useState(constructor.drivingLevel)
  const { updateDriverStatus } = useSeason(seasonId)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSaveInformations = async () => {
    var body = {
      constructorId: constructor.id,
      drivingLevel: drivingLevel,
      driverStatus: driverStatus,
    }
    var response = await updateDriverStatus(driver.id, body)
    if (response.status == 200) {
      handleClose()
    } else {
      console.error(response)
    }
  }

  return (
    <TableRow key={constructor.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">
        {constructor.name}
      </TableCell>
      <TableCell component="th" scope="row">
        {constructor.driverStatus}
      </TableCell>
      <TableCell component="th" scope="row">
        {constructor.drivingLevel}
      </TableCell>
      <TableCell component="th" scope="row">
        <ActionTableCell
          actions={[
            {
              variant: 'outlined',
              title: 'Edit',
              onClick: () => handleClickOpen(),
            },
          ]}
        />
      </TableCell>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Update {driver.firstName + ' ' + driver.lastName} status</DialogTitle>
        <DialogContent>
          {constructor.name}
          <DialogContentText id="alert-dialog-description">
            <FormControl fullWidth>
              <Grid
                container
                columnSpacing={2}
                paddingRight={6}
                paddingLeft={6}
                paddingBottom={2}
                paddingTop={2}
                rowSpacing={2}
              >
                <Grid item xs={24} display="flex" justifyContent="left" alignItems="left">
                  <Select
                    labelId="driverStatus-label"
                    id="driverStatus"
                    value={driverStatus}
                    label="Driver status"
                    onChange={(event) => {
                      setDriverStatus(event.target.value)
                    }}
                  >
                    <MenuItem value="Main">Main</MenuItem>
                    <MenuItem value="Substitute">Substitute</MenuItem>
                    <MenuItem value="Reservist">Reservist</MenuItem>
                    <MenuItem value="TestPilot">TestPilot</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={24} display="flex" justifyContent="left" alignItems="left">
                  <Select
                    labelId="drivingLevel-label"
                    id="drivingLevel"
                    value={drivingLevel}
                    label="Driving level"
                    onChange={(event) => {
                      setDrivingLevel(event.target.value)
                    }}
                  >
                    <MenuItem value="Practice">Practice</MenuItem>
                    <MenuItem value="Qualifying">Qualifying</MenuItem>
                    <MenuItem value="GrandPrix">GrandPrix</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Discard</Button>
          <Button onClick={handleSaveInformations} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </TableRow>
  )
}
