import { Button, Grid, MenuItem } from "@mui/material"

import InputGroup from "components/Shared/InputGroup"
import SelectGroup from "components/Shared/SelectGroup"

export default function AddEngineManufacturer({
  index,
  name,
  country,
  countries,
  constructorManufacturerId,
  constructors,
  setValue,
  handleRemoveLine,
}) {
  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      {handleRemoveLine && (
        <Grid
          item
          xs={12}
          marginTop={2}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant={"outlined"}
            color={"error"}
            onClick={handleRemoveLine}
          >
            Remove line
          </Button>
        </Grid>
      )}

      <Grid item xs={4}>
        <InputGroup
          id={`engine-manufacturer-name-${index}`}
          label='Name'
          autocomplete={false}
          value={name}
          onChange={(value) => setValue("name", value, index)}
        />
      </Grid>

      <Grid item xs={4}>
        <SelectGroup
          id={`engine-manufacturer-constructor-${index}`}
          label='Constructors'
          value={constructorManufacturerId}
          onChange={(value) =>
            setValue("constructorManufacturerId", value, index)
          }
        >
          <MenuItem value={null} style={{ padding: "16px" }}>
            {" "}
          </MenuItem>
          {constructors.map((constructor) => (
            <MenuItem key={constructor.id} value={constructor.id}>
              {constructor.name}
            </MenuItem>
          ))}
        </SelectGroup>
      </Grid>

      <Grid item xs={4}>
        <SelectGroup
          id={`engine-manufacturer-country-${index}`}
          label='Country'
          value={country}
          onChange={(value) => setValue("countryId", value, index)}
        >
          {countries.map((country) => (
            <MenuItem key={country.id} value={country.id}>
              {country.name}
            </MenuItem>
          ))}
        </SelectGroup>
      </Grid>
    </Grid>
  )
}
