import "../../App.css";

import { Button } from "@mui/material";
import Card from "../Shared/Card";
import CardBody from "../Shared/CardBody";
import CardFooter from "../Shared/CardFooter";
import KeyValue from "../Shared/KeyValue";
import { useNavigate } from "react-router-dom";

export default function Constructor({
  color,
  id,
  country,
  fullName,
  name,
  pictures,
  shortName,
  reference,
}) {
  const navigate = useNavigate();

  return (
    <Card>
      <CardBody>
        <KeyValue title="Id" values={[{ value: id }]} />
        <KeyValue title="Name" values={[{ value: name }]} />
        <KeyValue title="Reference" values={[{ value: reference }]} />
        <KeyValue title="FullName" values={[{ value: fullName }]} />
        <KeyValue title="ShortName" values={[{ value: shortName }]} />
        <KeyValue title="Color" values={[{ value: color, isColor: true }]} />
        <KeyValue title="Country" values={[{ value: country?.name }]} />
        <KeyValue
          title="Images"
          values={pictures.map((picture) => {
            return {
              value: picture.pictureType,
              link: picture.picture,
              isImage: true,
            };
          })}
        />
      </CardBody>
      <CardFooter>
        <Button
          size="medium"
          variant="outlined"
          onClick={() => navigate(`/constructors/${id}/histories`)}
        >
          Voir l'historique du constructeur
        </Button>
        <Button
          size="medium"
          variant="contained"
          onClick={() => navigate(`/constructors/${id}/edit`)}
        >
          Modifier le constructeur
        </Button>
      </CardFooter>
    </Card>
  );
}
