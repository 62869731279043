import "./index.css"

import { Typography } from "@mui/material"

export default function DisplayGroup({ groups }) {
  return (
    <>
      {groups.map((group, index) => {
        return (
          <div key={index} className='display-group-container'>
            <Typography component='div'>
              <Typography component='span' style={{ fontSize: "12px" }}>
                {group.title} :
              </Typography>
              <Typography
                component='span'
                style={{ fontSize: "12px", fontWeight: "bolder" }}
              >
                {" "}
                {group.value}
              </Typography>
            </Typography>
          </div>
        )
      })}
    </>
  )
}
