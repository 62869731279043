import "../../App.css";

import { Button } from "@mui/material";
import Card from "../Shared/Card";
import CardBody from "../Shared/CardBody";
import CardFooter from "../Shared/CardFooter";
import KeyValue from "../Shared/KeyValue";
import { useNavigate } from "react-router-dom";

export default function Driver({
  id,
  country,
  firstName,
  lastName,
  pictures,
  reference,
}) {
  const navigate = useNavigate();

  return (
    <Card>
      <CardBody>
        <KeyValue title="Id" values={[{ value: id }]} />
        <KeyValue title="Firstname" values={[{ value: firstName }]} />
        <KeyValue title="Lastname" values={[{ value: lastName }]} />
        <KeyValue title="Reference" values={[{ value: reference }]} />
        <KeyValue title="Country" values={[{ value: country?.name }]} />
        <KeyValue
          title="Images"
          values={pictures.map((picture) => {
            return {
              value: picture.pictureType,
              link: picture.picture,
              isImage: true,
            };
          })}
        />
      </CardBody>
      <CardFooter>
        <Button
          size="medium"
          variant="outlined"
          onClick={() => navigate(`/drivers/${id}/histories`)}
        >
          Voir l'historique du pilote
        </Button>
        <Button
          size="medium"
          variant="contained"
          onClick={() => navigate(`/drivers/${id}/edit`)}
        >
          Modifier le pilote
        </Button>
      </CardFooter>
    </Card>
  );
}
