import { drivers } from "../../api/drivers"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "react-query"

export default function useDriverHistory(driverId, historyId) {
  const types = [
    { id: 0, value: "PictureFirst" },
    { id: 1, value: "Number" },
  ]

  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const updateDriverHistory = async (body) => {
    const token = await getToken()
    return drivers.updateDriverHistory(driverId, historyId, body, token)
  }

  const queryResponse = useQuery(["driver-histories", { historyId }], () =>
    getToken().then((token) => {
      return drivers.getDriverHistoryById(driverId, historyId, token)
    })
  )

  return {
    ...queryResponse,
    history: queryResponse.data,
    types,
    updateDriverHistory,
  }
}
