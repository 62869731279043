import { useQuery, useQueryClient } from "react-query"

import { teams } from "core/api/teams"
import { useAuth0 } from "@auth0/auth0-react"

export default function useTeamHistory(teamId, historyId) {
  const queryClient = useQueryClient()
  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const queryResponse = useQuery(["team-history", { teamId, historyId }], () =>
    getToken().then((token) => {
      return teams.getTeamHistoryById(teamId, historyId, token)
    })
  )

  const updateTeamHistory = async (body) => {
    const token = await getToken()
    return teams
      .updateTeamHistory(
        teamId,
        historyId,
        {
          teamId,
          historyId,
          teamHistory: body,
        },
        token
      )
      .then((response) => {
        queryClient.removeQueries("team-histories")
        return response
      })
  }

  const deleteTeamHistory = async () => {
    const token = await getToken()
    return teams
      .deleteTeamHistory(teamId, historyId, token)
      .then((response) => {
        queryClient.removeQueries("team-histories")
        return response
      })
  }

  return {
    ...queryResponse,
    history: queryResponse?.data ?? null,
    updateTeamHistory,
    deleteTeamHistory,
  }
}
