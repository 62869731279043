import Drivers from "components/Drivers";
import LoadingProgress from "../../components/Shared/LoadingProgress";
import useDrivers from "core/hooks/useDrivers";
import { useLocation } from "react-router-dom";
import { useState } from "react";

export default function DriversPage() {
  const location = useLocation();

  const pageNumber = new URLSearchParams(location.search).get("pageNumber");
  const [page, setPage] = useState(pageNumber ?? 1);
  const { drivers, totalPages, isLoading, totalCount } = useDrivers(
    page,
    false
  );

  if (isLoading) {
    return <LoadingProgress />;
  }

  return (
    <Drivers
      drivers={drivers}
      page={page}
      setPage={setPage}
      totalPages={totalPages}
      totalCount={totalCount}
    />
  );
}
