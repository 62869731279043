import {
  Alert,
  Button,
  Container,
  FormControl,
  Grid,
  ImageListItem,
  TextField,
  Typography,
} from "@mui/material";

import { CheckCircleOutline } from "@mui/icons-material";
import LoadingProgress from "../../components/Shared/LoadingProgress";
import Navigation from "../../components/Navigation/Index";
import React from "react";
import formValue from "core/utils/formValue";
import useDriverHistory from "core/hooks/useDriverHistory";
import { useParams } from "react-router-dom";

export default function DriverHistoryEditPage() {
  const params = useParams();

  const [code, setCode] = React.useState("");
  const [number, setNumber] = React.useState(null);
  const [birthDate, setBirthDate] = React.useState("");
  const [deathDate, setDeathDate] = React.useState("");
  const [pictures, setPictures] = React.useState([]);
  const [successDisplay, setSuccessDisplay] = React.useState(false);
  const [errorDisplay, setErrorDisplay] = React.useState(false);

  const { history, refetch, isLoading, types, updateDriverHistory } =
    useDriverHistory(params.driverId, params.historyId);

  React.useEffect(() => {
    if (history != null) {
      setCode(history.code);
      setNumber(history.number);
      setBirthDate(history.birthDate);
      setDeathDate(history.deathDate);
      setPictures(history.pictures);
    }
  }, [history]);

  if (isLoading) {
    return <LoadingProgress />;
  }

  const updatePicture = (pictureUrl, type) => {
    const indexPictureType = pictures.findIndex((p) => p.pictureType === type);

    if (pictureUrl.trim() === "" && indexPictureType === -1) {
      return;
    }

    if (indexPictureType === -1) {
      setPictures([...pictures, { picture: pictureUrl, pictureType: type }]);
      return;
    }

    const picturesUpdated = pictures.map((p, i) => {
      if (i === indexPictureType) {
        return { picture: pictureUrl, pictureType: type };
      } else {
        return p;
      }
    });

    setPictures(picturesUpdated);
  };

  const handleSubmit = () => {
    updateDriverHistory({
      code,
      number,
      birthDate,
      deathDate,
      pictures,
    }).then((response) => {
      if (response.ok === true) {
        setSuccessDisplay(true);
        refetch();
      } else {
        setErrorDisplay(true);
      }
    });
  };

  return (
    <Container>
      <FormControl fullWidth>
        <Grid
          container
          columnSpacing={2}
          paddingRight={6}
          paddingLeft={6}
          paddingBottom={2}
          paddingTop={2}
          rowSpacing={2}
        >
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h2">Edit driver</Typography>
          </Grid>
          <Grid item xs={12}>
            <Navigation
              navs={[
                { link: `/drivers/`, title: "Drivers" },
                {
                  link: `/drivers/${params.driverId}/edit`,
                  title: params.driverId,
                },
                {
                  link: `/drivers/${params.driverId}/histories`,
                  title: "Histories",
                },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            {successDisplay && (
              <Alert
                icon={<CheckCircleOutline fontSize="inherit" />}
                severity="success"
              >
                This is a success Alert.
              </Alert>
            )}
            {errorDisplay && (
              <Alert severity="error">This is a error Alert.</Alert>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <TextField
              id="driverId"
              label="Id"
              defaultValue={history.id}
              fullWidth
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <TextField
              id="driverSeason"
              label="Season"
              defaultValue={history.season.year}
              fullWidth
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <TextField
              id="driverName"
              label="Name"
              defaultValue={history.name}
              fullWidth
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <TextField
              id="driverCountry"
              label="Country"
              defaultValue={history.country.name}
              fullWidth
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <TextField
              id="driverNumber"
              label="Number"
              value={number ?? ""}
              fullWidth
              onChange={(event) => {
                setSuccessDisplay(false);
                setErrorDisplay(false);
                setNumber(formValue(event.target.value));
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <TextField
              id="driverCode"
              label="Code"
              value={code ?? ""}
              fullWidth
              onChange={(event) => {
                setSuccessDisplay(false);
                setErrorDisplay(false);
                setCode(event.target.value);
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <TextField
              id="driverBirthDate"
              label="Birth Date"
              value={birthDate ?? ""}
              fullWidth
              onChange={(event) => {
                setSuccessDisplay(false);
                setErrorDisplay(false);
                setBirthDate(formValue(event.target.value));
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <TextField
              id="driverDeathDate"
              label="Death Date"
              value={deathDate ?? ""}
              fullWidth
              onChange={(event) => {
                setSuccessDisplay(false);
                setErrorDisplay(false);
                setDeathDate(formValue(event.target.value));
              }}
            />
          </Grid>

          {types.map((type) => {
            const picture = pictures.find((p) => p.pictureType === type.value);
            return (
              <React.Fragment key={type.id}>
                <Grid
                  item
                  xs={picture ? 10 : 12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <TextField
                    id={`driverPicture${type.id}`}
                    label={type.value}
                    value={picture?.picture ?? ""}
                    fullWidth
                    onChange={(event) => {
                      setSuccessDisplay(false);
                      setErrorDisplay(false);
                      updatePicture(event.target.value, type.value);
                    }}
                  />
                </Grid>

                {picture?.picture != null && (
                  <Grid
                    item
                    xs={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ImageListItem>
                      <img
                        srcSet={picture.picture}
                        src={picture.picture}
                        alt={type.value}
                        style={{ maxHeight: 100 }}
                        loading="lazy"
                      />
                    </ImageListItem>
                  </Grid>
                )}
              </React.Fragment>
            );
          })}
        </Grid>
        <Grid item display="flex" justifyContent="center" alignItems="center">
          <Button size="medium" variant="contained" onClick={handleSubmit}>
            Save
          </Button>
        </Grid>
      </FormControl>
    </Container>
  );
}
