import { useNavigate, useParams } from "react-router-dom"

import AddTeamHistory from "components/AddTeamHistory"
import { CircularProgress } from "@mui/material"
import FormPageContainer from "components/Shared/FormPageContainer"
import React from "react"
import useAllConstructorManufacturers from "core/hooks/useAllConstructorManufacturers"
import useAllConstructors from "core/hooks/useAllConstructors"
import useAllEngineManufacturers from "core/hooks/useAllEngineManufacturers"
import useCountries from "core/hooks/useCountries"
import useSeasons from "core/hooks/useSeasons"
import useTeam from "core/hooks/useTeam"
import useTeamHistories from "core/hooks/useTeamHistories"

export default function TeamHistoriesAddPage() {
  const { teamId } = useParams()

  const { addTeamHistories } = useTeamHistories(teamId)

  const { team } = useTeam(teamId)

  const { countries, isLoading: isLoadingCountries } = useCountries()
  const { constructorManufacturers, isLoading: isLoadingConstructors } =
    useAllConstructorManufacturers()
  const { engineManufacturers, isLoading: isLoadingEngineManufacturers } =
    useAllEngineManufacturers()

  const { seasons, isLoading: isLoadingSeasons } = useSeasons()

  const {
    constructors: legacyConstructors,
    isLoading: isLoadingConstructorsLegacy,
  } = useAllConstructors()

  const [histories, setTeamHistories] = React.useState([])
  const [isError, setIsError] = React.useState(false)

  const navigate = useNavigate()

  React.useEffect(() => {
    if (
      team != null &&
      seasons?.length > 0 &&
      countries?.length > 0 &&
      constructorManufacturers?.length > 0 &&
      engineManufacturers?.length > 0 &&
      legacyConstructors?.length > 0
    ) {
      setTeamHistories([
        {
          fullName: team.fullName,
          shortName: team.shortName,
          color: team.color,
          constructors: [
            {
              constructorId: team.constructors[0].constructor.id,
              engineManufacturerId: team.constructors[0].engineManufacturer.id,
              constructorLegacyId: team.constructors[0].constructorLegacy.id,
            },
          ],
          countryId: team.country.id,
          seasonId: seasons[0]?.id,
        },
      ])
    }
  }, [
    team,
    seasons,
    countries,
    constructorManufacturers,
    engineManufacturers,
    legacyConstructors,
  ])

  if (
    isLoadingCountries ||
    isLoadingConstructors ||
    isLoadingEngineManufacturers ||
    isLoadingConstructorsLegacy ||
    isLoadingSeasons
  ) {
    return <CircularProgress />
  }

  const setValue = (property, value, index) => {
    isError && setIsError(false)
    const teamsUpdated = histories.map((c, i) => {
      if (i === index) {
        c[property] = value
      }
      return c
    })

    setTeamHistories(teamsUpdated)
  }

  const setConstructorsValue = (
    property,
    value,
    teamIndex,
    constructorIndex
  ) => {
    isError && setIsError(false)
    const teamsUpdated = histories.map((c, i) => {
      if (i === teamIndex) {
        c.constructors = c.constructors.map((constructor, j) => {
          if (j === constructorIndex) {
            constructor[property] = value
          }
          return constructor
        })
      }
      return c
    })

    setTeamHistories(teamsUpdated)
  }

  const handleAddTeamHistory = () => {
    const lastSeason = seasons.find(
      (season) => season.id === histories[histories.length - 1].seasonId
    )

    const previousHistory = histories[histories.length - 1]

    setTeamHistories((c) => [
      ...c,
      {
        fullName: previousHistory.fullName,
        shortName: previousHistory.shortName,
        color: previousHistory.color,
        constructors: [
          {
            constructorId: previousHistory.constructors[0].constructorId,
            engineManufacturerId:
              previousHistory.constructors[0].engineManufacturerId,
            constructorLegacyId:
              previousHistory.constructors[0].constructorLegacyId,
          },
        ],
        countryId: previousHistory.countryId,
        seasonId: seasons.find((season) => season.year + 1 === lastSeason.year)
          .id,
      },
    ])
  }

  const handleRemoveLine = (index) => {
    if (histories.length === 1) {
      return
    }

    const historiesUpdated = histories.filter((_, i) => i !== index)
    setTeamHistories(historiesUpdated)
  }

  const handleAddConstructor = (index) => {
    const teamsUpdated = histories.map((c, i) => {
      if (i === index) {
        c.constructors.push({
          constructorId: constructorManufacturers[0]?.id,
          engineManufacturerId: engineManufacturers[0]?.id,
          constructorLegacyId: legacyConstructors[0]?.id,
        })
      }
      return c
    })

    setTeamHistories(teamsUpdated)
  }

  const handleRemoveConstructor = (index, constructorIndex) => {
    const teamsUpdated = histories.map((c, i) => {
      if (i === index) {
        c.constructors = c.constructors.filter((_, j) => j !== constructorIndex)
      }
      return c
    })

    setTeamHistories(teamsUpdated)
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    const result = await addTeamHistories({ histories })
    if (result.status === 204) {
      navigate(`/teams/${teamId}/histories`)
      return
    }

    setIsError(true)
  }

  return (
    <FormPageContainer
      buttonText={"Add team histories"}
      handleButton={handleAddTeamHistory}
      isError={isError}
      handleSubmit={handleSubmit}
      title={"Create new histories for team: " + team?.fullName}
      navigations={[
        { link: `/teams`, title: "teams" },
        {
          link: `/teams/${teamId}/edit`,
          title: teamId,
        },
        {
          link: `/teams/${teamId}/histories`,
          title: "histories",
        },
      ]}
    >
      {histories.map((team, index) => (
        <React.Fragment key={index}>
          <AddTeamHistory
            index={index}
            color={team.color}
            engineManufacturers={engineManufacturers}
            fullName={team.fullName}
            shortName={team.shortName}
            constructorManufacturers={constructorManufacturers}
            country={team.countryId}
            countries={countries}
            setValue={setValue}
            legacyConstructors={legacyConstructors}
            seasonId={team.seasonId}
            seasons={seasons}
            constructors={team.constructors}
            setConstructorsValue={setConstructorsValue}
            handleAddConstructor={() => handleAddConstructor(index)}
            handleRemoveConstructor={handleRemoveConstructor}
            handleRemoveLine={
              histories.length > 1 ? () => handleRemoveLine(index) : null
            }
          />
          <hr />
        </React.Fragment>
      ))}
    </FormPageContainer>
  )
}
