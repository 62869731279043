import {
  Alert,
  Button,
  Container,
  FormControl,
  Grid,
  ImageListItem,
  Link,
  TextField,
  Typography,
} from "@mui/material"

import { CheckCircleOutline } from "@mui/icons-material"
import LoadingProgress from "../../components/Shared/LoadingProgress"
import Navigation from "../../components/Navigation/Index"
import React from "react"
import formValue from "core/utils/formValue"
import useDriver from "core/hooks/useDriver"
import { useParams } from "react-router-dom"

export default function DriverEditPage() {
  const params = useParams()
  const driverId = params.driverId

  const [code, setCode] = React.useState("")
  const [number, setNumber] = React.useState(null)
  const [birthDate, setBirthDate] = React.useState("")
  const [deathDate, setDeathDate] = React.useState("")
  const [pictures, setPictures] = React.useState([])
  const [successDisplay, setSuccessDisplay] = React.useState(false)
  const [errorDisplay, setErrorDisplay] = React.useState(false)

  const { driver, refetch, isLoading, types, updateDriver } =
    useDriver(driverId)

  React.useEffect(() => {
    if (driver != null) {
      setCode(driver.code)
      setNumber(driver.number)
      setBirthDate(driver.birthDate)
      setDeathDate(driver.deathDate)
      setPictures(driver.pictures)
    }
  }, [driver])

  if (isLoading) {
    return <LoadingProgress />
  }

  const updatePicture = (pictureUrl, type) => {
    const indexPictureType = pictures.findIndex((p) => p.pictureType === type)

    if (pictureUrl.trim() === "" && indexPictureType === -1) {
      return
    }

    if (indexPictureType === -1) {
      setPictures([...pictures, { picture: pictureUrl, pictureType: type }])
      return
    }

    const picturesUpdated = pictures.map((p, i) => {
      if (i === indexPictureType) {
        return { picture: pictureUrl, pictureType: type }
      } else {
        return p
      }
    })

    setPictures(picturesUpdated)
  }

  const handleSubmit = () => {
    updateDriver({
      code,
      number,
      birthDate,
      pictures,
    }).then((response) => {
      if (response.ok === true) {
        setSuccessDisplay(true)
        refetch()
      } else {
        setErrorDisplay(true)
      }
    })
  }

  return (
    <Container>
      <FormControl fullWidth>
        <Grid
          container
          columnSpacing={2}
          paddingRight={6}
          paddingLeft={6}
          paddingBottom={2}
          paddingTop={2}
          rowSpacing={2}
        >
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <Typography variant='h2'>Edit driver</Typography>
          </Grid>
          <Grid item xs={12}>
            <Navigation navs={[{ link: `/drivers/`, title: "Drivers" }]} />
          </Grid>
          <Grid item xs={12}>
            <Link href={`/drivers/${driverId}/histories`}>
              Voir l'historique du constructeur
            </Link>
          </Grid>
          <Grid item xs={12}>
            {successDisplay && (
              <Alert
                icon={<CheckCircleOutline fontSize='inherit' />}
                severity='success'
              >
                This is a success Alert.
              </Alert>
            )}
            {errorDisplay && (
              <Alert severity='error'>This is a error Alert.</Alert>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='driverId'
              label='Id'
              defaultValue={driver.id}
              fullWidth
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='driverName'
              label='Name'
              defaultValue={driver.name}
              fullWidth
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='driverCountry'
              label='Country'
              defaultValue={driver.country.name}
              fullWidth
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='driverNumber'
              label='Number'
              value={number ?? ""}
              fullWidth
              onChange={(event) => {
                setSuccessDisplay(false)
                setErrorDisplay(false)
                setNumber(formValue(event.target.value))
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='driverCode'
              label='Code'
              value={code ?? ""}
              fullWidth
              onChange={(event) => {
                setSuccessDisplay(false)
                setErrorDisplay(false)
                setCode(event.target.value)
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='driverBirthDate'
              label='Birth Date'
              value={birthDate ?? ""}
              fullWidth
              onChange={(event) => {
                setSuccessDisplay(false)
                setErrorDisplay(false)
                setBirthDate(formValue(event.target.value))
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='driverDeathDate'
              label='Death Date'
              value={deathDate ?? ""}
              fullWidth
              onChange={(event) => {
                setSuccessDisplay(false)
                setErrorDisplay(false)
                setDeathDate(formValue(event.target.value))
              }}
            />
          </Grid>
          {types.map((type) => {
            const picture = pictures.find((p) => p.pictureType === type.value)
            return (
              <React.Fragment key={type.id}>
                <Grid
                  item
                  xs={picture ? 10 : 12}
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                >
                  <TextField
                    id={`driverPicture${type.id}`}
                    label={type.value}
                    value={picture?.picture ?? ""}
                    fullWidth
                    onChange={(event) => {
                      setSuccessDisplay(false)
                      setErrorDisplay(false)
                      updatePicture(event.target.value, type.value)
                    }}
                  />
                </Grid>

                {picture?.picture != null && (
                  <Grid
                    item
                    xs={2}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <ImageListItem>
                      <img
                        srcSet={picture.picture}
                        src={picture.picture}
                        alt={type.value}
                        style={{ maxHeight: 100 }}
                        loading='lazy'
                      />
                    </ImageListItem>
                  </Grid>
                )}
              </React.Fragment>
            )
          })}
        </Grid>
        <Grid item display='flex' justifyContent='center' alignItems='center'>
          <Button size='medium' variant='contained' onClick={handleSubmit}>
            Save
          </Button>
        </Grid>
      </FormControl>
    </Container>
  )
}
