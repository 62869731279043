import { grandsPrix } from "core/api/grandsPrix"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "react-query"

export default function useGrandPrix(gpId) {
  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const queryResponse = useQuery(["grands-prix", { gpId }], () =>
    getToken().then((token) => {
      return grandsPrix.getGrandPrixById(gpId, token)
    })
  )

  const updateGrandPrix = async (body) => {
    const token = await getToken()
    return grandsPrix.updateGrandPrix(gpId, body, token)
  }

  return {
    ...queryResponse,
    grandPrix: queryResponse.data,
    updateGrandPrix,
  }
}
