import "./index.scss"

import { Link } from "@mui/material"
import React from "react"

export default function Navigation({ navs }) {
  return (
    <div>
      {navs.map((nav, index) => {
        if (index === 0) {
          return (
            <Link key={index} href={nav.link} className='navigation-link'>
              {nav.title}
            </Link>
          )
        }

        return (
          <React.Fragment key={index}>
            <span className='navigation-separator'>&gt;</span>
            <Link href={nav.link} className='navigation-link'>
              {`${nav.title}`}
            </Link>
          </React.Fragment>
        )
      })}
    </div>
  )
}
