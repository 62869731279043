import apiService from "core/services/network/api"
import responseToJson from "core/utils/reponseToJson"

export const constructors = {
  getConstructors: (page, isCurrentSeason, token) => {
    return apiService
      .getData(
        `v1/constructors?isCurrentSeason=${isCurrentSeason}&pageNumber=${page}&pageSize=50&sortBy=asc(Name)`,
        token
      )
      .then(responseToJson)
  },
  getAllConstructors: (token) => {
    return apiService
      .getData(`v1/constructors?sortBy=asc(Name)&all=true`, token)
      .then(responseToJson)
  },
  getConstructorById: (constructorId, token) => {
    return apiService
      .getData(`v1/constructors/${constructorId}`, token)
      .then(responseToJson)
  },
  getConstructorHistoryById: (constructorId, historyId, token) => {
    return apiService
      .getData(`v1/constructors/${constructorId}/histories/${historyId}`, token)
      .then(responseToJson)
  },
  getConstructorsHistoriesByConstructorId: (constructorId, token) => {
    return apiService
      .getData(`v1/constructors/${constructorId}/histories`, token)
      .then(responseToJson)
  },
  updateConstructor: (constructorId, body, token) => {
    return apiService.patch(
      `v1/constructors/${constructorId}`,
      JSON.stringify(body),
      token
    )
  },
  updateConstructorHistory: (constructorId, historyId, body, token) => {
    return apiService.patch(
      `v1/constructors/${constructorId}/histories/${historyId}`,
      JSON.stringify(body),
      token
    )
  },
}
