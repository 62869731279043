import { useLocation, useNavigate } from "react-router-dom"

import ListPageContainer from "components/Shared/ListPageContainer"
import LoadingProgress from "components/Shared/LoadingProgress"
import TeamsList from "components/TeamsList"
import useTeams from "core/hooks/useTeams"

export default function TeamsPage() {
  const navigate = useNavigate()

  const location = useLocation()
  const pageNumber = new URLSearchParams(location.search).get("pageNumber") ?? 1
  const pageSize = new URLSearchParams(location.search).get("pageSize") ?? 100

  const {
    teams,
    isLoading: isLoadingTeams,
    totalPages,
    totalCount,
  } = useTeams(pageNumber, pageSize)

  if (isLoadingTeams) {
    return <LoadingProgress />
  }

  return (
    <ListPageContainer
      title={`Teams: ${totalCount}`}
      buttonText={"Add new teams"}
      handleButton={() => navigate("/create-teams")}
      totalPages={totalPages}
      currentPage={parseInt(pageNumber)}
      pageSize={pageSize}
      paginationLink={"teams"}
    >
      <TeamsList teams={teams} />
    </ListPageContainer>
  )
}
