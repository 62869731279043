import Constructors from "components/Constructors";
import LoadingProgress from "../../components/Shared/LoadingProgress";
import useConstructors from "../../core/hooks/useConstructors";
import { useLocation } from "react-router-dom";
import { useState } from "react";

export default function ConstructorsPage() {
  const location = useLocation();

  const pageNumber = new URLSearchParams(location.search).get("pageNumber");
  const [page, setPage] = useState(pageNumber ?? 1);
  const { constructors, totalPages, isLoading, totalCount } = useConstructors(
    page,
    false
  );

  if (isLoading) {
    return <LoadingProgress />;
  }

  return (
    <Constructors
      constructors={constructors}
      page={page}
      setPage={setPage}
      totalPages={totalPages}
      totalCount={totalCount}
    />
  );
}
