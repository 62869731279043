import { Button, Grid } from "@mui/material"

export default function FormContainer({
  handleSubmit,
  buttonText = "Save",
  buttonColor = "primary",
  justifyContent = "center",
  children,
}) {
  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <Grid
        container
        columnSpacing={2}
        paddingBottom={2}
        paddingTop={2}
        rowSpacing={2}
      >
        {children}
      </Grid>
      <Grid
        item
        display='flex'
        justifyContent={justifyContent}
        alignItems='center'
      >
        <Button
          type='submit'
          size='medium'
          variant='contained'
          color={buttonColor}
        >
          {buttonText}
        </Button>
      </Grid>
    </form>
  )
}
