import apiService from 'core/services/network/api'
import responseToJson from 'core/utils/reponseToJson'

export const seasons = {
  getSeasons: (token) => {
    return apiService.getData(`v1/seasons`, token).then(responseToJson)
  },
  getSeasonById: (seasonId, token) => {
    return apiService.getData(`v1/seasons/${seasonId}`, token).then(responseToJson)
  },
  updateDriverStatus: (seasonId, driverId, body, token) => {
    return apiService.update(`v1/seasons/${seasonId}/drivers/${driverId}/status`, JSON.stringify(body), token)
  },
}
