import { Button } from "@mui/material";
import Card from "../Shared/Card";
import CardBody from "../Shared/CardBody";
import CardFooter from "../Shared/CardFooter";
import KeyValue from "../Shared/KeyValue";
import { useNavigate } from "react-router-dom";

export default function ConstructorHistory({
  color,
  constructorId,
  country,
  drivers,
  fullName,
  id,
  name,
  pictures,
  season,
  shortName,
  standing,
}) {
  const navigate = useNavigate();

  return (
    <Card>
      <CardBody>
        <KeyValue title="Id" values={[{ value: id }]} />
        <KeyValue title="ConstructorId" values={[{ value: constructorId }]} />
        <KeyValue title="Name" values={[{ value: name }]} />
        <KeyValue
          title="Season"
          values={[{ value: season.id }, { value: season.year }]}
        />
        <KeyValue title="FullName" values={[{ value: fullName }]} />
        <KeyValue title="ShortName" values={[{ value: shortName }]} />
        <KeyValue title="Color" values={[{ value: color, isColor: true }]} />
        <KeyValue title="Country" values={[{ value: country.name }]} />
        <KeyValue
          title="Drivers"
          values={drivers.map((driver) => {
            return {
              value: `${driver.firstName} ${driver.lastName} ${driver.id}`,
            };
          })}
        />
        <KeyValue
          title="Standing"
          values={[
            { value: standing?.isChampion },
            { value: standing?.stadingPoints },
            { value: standing?.standingPosition },
          ]}
        />
        <KeyValue
          title="Images"
          values={pictures.map((picture) => {
            return {
              value: picture.pictureType,
              link: picture.picture,
              isImage: true,
            };
          })}
        />
      </CardBody>
      <CardFooter>
        <Button
          size="medium"
          variant="contained"
          onClick={() =>
            navigate(`/constructors/${constructorId}/histories/${id}/edit`)
          }
        >
          Modifier l'historique
        </Button>
      </CardFooter>
    </Card>
  );
}
