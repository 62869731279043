import { constructors } from "../../api/constructors"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "react-query"

export default function useConstructorHistories(constructorId) {
  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const queryResponse = useQuery(
    ["constructor-histories", { constructorId }],
    () =>
      getToken().then((token) => {
        return constructors.getConstructorsHistoriesByConstructorId(
          constructorId,
          token
        )
      })
  )

  return {
    ...queryResponse,
    histories: queryResponse?.data ?? [],
  }
}

export const histories = {}
