import { useLocation, useNavigate } from "react-router-dom"

import ConstructorManufacturersList from "components/ConstructorManufacturersList"
import ListPageContainer from "components/Shared/ListPageContainer"
import LoadingProgress from "components/Shared/LoadingProgress"
import React from "react"
import useConstructorManufacturers from "core/hooks/useConstructorManufacturers"

export default function ConstructorManufacturersPage() {
  const navigate = useNavigate()

  const location = useLocation()
  const pageNumber = new URLSearchParams(location.search).get("pageNumber") ?? 1
  const pageSize = new URLSearchParams(location.search).get("pageSize") ?? 100

  const { constructorManufacturers, isLoading, totalPages, totalCount } =
    useConstructorManufacturers(pageNumber, pageSize)

  if (isLoading) {
    return <LoadingProgress />
  }

  return (
    <ListPageContainer
      title={`Constructors: ${totalCount}`}
      buttonText={"Add new constructors"}
      handleButton={() => navigate("/create-constructor-manufacturers")}
      totalPages={totalPages}
      currentPage={parseInt(pageNumber)}
      pageSize={pageSize}
      paginationLink={"constructor-manufacturers"}
    >
      <ConstructorManufacturersList constructors={constructorManufacturers} />
    </ListPageContainer>
  )
}
