import { useLocation, useNavigate } from "react-router-dom"

import EngineManufacturersList from "components/EngineManufacturerList"
import ListPageContainer from "components/Shared/ListPageContainer"
import LoadingProgress from "components/Shared/LoadingProgress"
import useEngineManufacturers from "core/hooks/useEngineManufacturers"

export default function EngineManufacturersPage() {
  const navigate = useNavigate()

  const location = useLocation()
  const pageNumber = new URLSearchParams(location.search).get("pageNumber") ?? 1
  const pageSize = new URLSearchParams(location.search).get("pageSize") ?? 100

  const { engineManufacturers, isLoading, totalPages, totalCount } =
    useEngineManufacturers(pageNumber, pageSize)

  if (isLoading) {
    return <LoadingProgress />
  }

  return (
    <ListPageContainer
      title={`Engine manufacturers: ${totalCount}`}
      buttonText={"Add engine manufacturer"}
      handleButton={() => navigate("/create-engine-manufacturers")}
      totalPages={totalPages}
      currentPage={pageNumber == null ? 1 : parseInt(pageNumber)}
      paginationLink={"engine-manufacturers"}
      pageSize={pageSize}
    >
      <EngineManufacturersList engineManufacturers={engineManufacturers} />
    </ListPageContainer>
  )
}
