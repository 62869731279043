import { Alert, Button, Grid, Typography } from "@mui/material"

import FormContainer from "../FormContainer"
import Navigation from "components/Navigation/Index"

export default function FormPageContainer({
  title,
  isError,
  buttonText,
  handleButton,
  handleSubmit,
  navigations,
  children,
}) {
  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography variant='h4' component='h1'>
          {title}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {isError && <Alert severity='error'>Error during the teams add</Alert>}
      </Grid>

      <Grid
        item
        xs={12}
        display='flex'
        justifyContent='flex-end'
        alignItems='center'
        alignSelf='stretch'
      >
        <Button variant='contained' onClick={handleButton}>
          {buttonText}
        </Button>
      </Grid>

      {navigations?.length > 0 && (
        <Grid item xs={12}>
          <Navigation navs={navigations} />
        </Grid>
      )}

      <Grid item xs={12}>
        <FormContainer handleSubmit={handleSubmit}>{children}</FormContainer>
      </Grid>
    </Grid>
  )
}
