import { Container, Grid, Pagination, Typography } from "@mui/material";

import Driver from "components/Driver";
import { useNavigate } from "react-router-dom";

export default function Drivers({
  drivers,
  totalPages,
  page,
  setPage,
  totalCount,
}) {
  const navigate = useNavigate();

  if (drivers.length === 0) {
    return (
      <Container>
        <Grid
          container
          paddingRight={6}
          paddingLeft={6}
          paddingBottom={2}
          paddingTop={2}
          rowSpacing={5}
        >
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h3">No data to display</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {totalPages !== 0 && (
              <Pagination
                count={totalPages}
                page={page}
                onChange={(event, value) => {
                  setPage(value);
                  navigate(`/drivers?pageNumber=${value}`);
                }}
                showFirstButton
                showLastButton
              />
            )}
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Container>
      <Grid
        container
        columnSpacing={2}
        paddingRight={6}
        paddingLeft={6}
        paddingBottom={2}
        paddingTop={2}
        rowSpacing={2}
      >
        <Grid item xs={12}>
          <Typography variant="h2">Drivers</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Nb Totals: {totalCount}</Typography>
        </Grid>
        {drivers.map((item) => {
          return (
            <Grid item key={item.id} xs={12}>
              <Driver
                id={item.id}
                firstName={item.firstName}
                lastName={item.lastName}
                country={item.country}
                pictures={item.pictures}
                reference={item.reference}
              />
            </Grid>
          );
        })}
        {setPage && totalPages && page && (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={(event, value) => {
                setPage(value);
                navigate(`/drivers?pageNumber=${value}`);
              }}
              showFirstButton
              showLastButton
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
