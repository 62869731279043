import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"

import ActionTableCell from "components/Shared/ActionTableCell"
import DisplayGroup from "components/Shared/DisplayGroup"
import NoDataToDisplay from "components/Shared/NoDataToDisplay"
import { useNavigate } from "react-router-dom"

export default function TeamsList({ teams }) {
  const navigate = useNavigate()

  if (teams.length === 0) {
    return <NoDataToDisplay />
  }

  return (
    <Table stickyHeader aria-label='simple table'>
      <TableHead>
        <TableRow>
          <TableCell component='th'>Id</TableCell>
          <TableCell component='th'>Name</TableCell>
          <TableCell component='th'>Color</TableCell>
          <TableCell component='th'>Constructor</TableCell>
          <TableCell component='th'>Engine Manufacturer</TableCell>
          <TableCell component='th'>Constructor legacy</TableCell>
          <TableCell component='th'>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {teams.map((team) => (
          <TableRow key={team.id}>
            <TableCell component='td'>
              <Typography component='span' style={{ fontSize: "12px" }}>
                {team.id}
              </Typography>
            </TableCell>
            <TableCell component='td'>
              <DisplayGroup
                groups={[
                  { title: "full name", value: team.fullName },
                  { title: "short name", value: team.shortName },
                ]}
              />
            </TableCell>
            <TableCell component='td'>
              {team.color && (
                <div className='flex-col flex-center'>
                  <Typography component='span' style={{ fontSize: "12px" }}>
                    {`#${team.color}`}
                  </Typography>
                  <span
                    className='color'
                    style={{ backgroundColor: `#${team.color}` }}
                  />
                </div>
              )}
            </TableCell>
            <TableCell component='td'>
              {team.constructors.map((constructor, index) => (
                <DisplayGroup
                  key={`${team.id}-${constructor.constructor.id}-${index}`}
                  groups={[
                    { title: "id", value: constructor.constructor.id },
                    { title: "name", value: constructor.constructor.name },
                  ]}
                />
              ))}
            </TableCell>
            <TableCell component='td'>
              {team.constructors.map((constructor, index) => (
                <DisplayGroup
                  key={`${team.id}-${constructor.engineManufacturer.id}-${index}`}
                  groups={[
                    { title: "id", value: constructor.engineManufacturer.id },
                    {
                      title: "name",
                      value: constructor.engineManufacturer.name,
                    },
                  ]}
                />
              ))}
            </TableCell>
            <TableCell component='td'>
              {team.constructors.map((constructor, index) => (
                <DisplayGroup
                  key={`${team.id}-${constructor.constructorLegacy.id}-${index}`}
                  groups={[
                    { title: "id", value: constructor.constructorLegacy.id },
                    {
                      title: "name",
                      value: constructor.constructorLegacy.name,
                    },
                  ]}
                />
              ))}
            </TableCell>
            <TableCell component='td'>
              <ActionTableCell
                actions={[
                  {
                    variant: "outlined",
                    color: "success",
                    title: "Histories",
                    onClick: () => navigate(`/teams/${team.id}/histories`),
                  },
                  {
                    variant: "outlined",
                    title: "Edit",
                    onClick: () => navigate(`/teams/${team.id}/edit`),
                  },
                ]}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
