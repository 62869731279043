import {
  Alert,
  Button,
  Chip,
  Container,
  FormControl,
  Grid,
  ImageListItem,
  Link,
  TextField,
  Typography,
} from "@mui/material"

import { CheckCircleOutline } from "@mui/icons-material"
import LoadingProgress from "../../components/Shared/LoadingProgress"
import Navigation from "../../components/Navigation/Index"
import React from "react"
import useConstructor from "core/hooks/useConstructor"
import { useParams } from "react-router-dom"

export default function ConstructorEditPage() {
  const params = useParams()
  const constructorId = params.constructorId

  const [color, setColor] = React.useState("")
  const [fullName, setFullName] = React.useState("")
  const [shortName, setShortName] = React.useState("")
  const [pictures, setPictures] = React.useState([])
  const [successDisplay, setSuccessDisplay] = React.useState(false)
  const [errorDisplay, setErrorDisplay] = React.useState(false)

  const { constructor, refetch, isLoading, types, updateConstructor } =
    useConstructor(constructorId)

  React.useEffect(() => {
    if (constructor != null) {
      setColor(constructor.color)
      setFullName(constructor.fullName)
      setShortName(constructor.shortName)
      setPictures(constructor.pictures)
    }
  }, [constructor])

  if (isLoading) {
    return <LoadingProgress />
  }

  if (constructor == null) {
    return
  }

  const updatePicture = (pictureUrl, type) => {
    const indexPictureType = pictures.findIndex((p) => p.pictureType === type)

    if (pictureUrl.trim() === "" && indexPictureType === -1) {
      return
    }

    if (indexPictureType === -1) {
      setPictures([...pictures, { picture: pictureUrl, pictureType: type }])
      return
    }

    const picturesUpdated = pictures.map((p, i) => {
      if (i === indexPictureType) {
        return { picture: pictureUrl, pictureType: type }
      } else {
        return p
      }
    })

    setPictures(picturesUpdated)
  }

  const handleSubmit = () => {
    updateConstructor({
      color,
      fullName,
      shortName,
      pictures,
    }).then((response) => {
      if (response.ok === true) {
        setSuccessDisplay(true)
        refetch()
      } else {
        setErrorDisplay(true)
      }
    })
  }

  return (
    <Container>
      <FormControl fullWidth>
        <Grid
          container
          columnSpacing={2}
          paddingRight={6}
          paddingLeft={6}
          paddingBottom={2}
          paddingTop={2}
          rowSpacing={2}
        >
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <Typography variant='h2'>Edit constructor</Typography>
          </Grid>
          <Grid item xs={12}>
            <Navigation
              navs={[{ link: `/constructors/`, title: "Constructors" }]}
            />
          </Grid>
          <Grid item xs={12}>
            <Link href={`/constructors/${constructorId}/histories`}>
              Voir l'historique du constructeur
            </Link>
          </Grid>
          <Grid item xs={12}>
            {successDisplay && (
              <Alert
                icon={<CheckCircleOutline fontSize='inherit' />}
                severity='success'
              >
                This is a success Alert.
              </Alert>
            )}
            {errorDisplay && (
              <Alert severity='error'>This is a error Alert.</Alert>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='constructorId'
              label='Id'
              defaultValue={constructor.id}
              fullWidth
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='constructorName'
              label='Name'
              defaultValue={constructor.name}
              fullWidth
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='constructorCountry'
              label='Country'
              defaultValue={constructor.country.name}
              fullWidth
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='constructorFullName'
              label='Full Name'
              value={fullName ?? ""}
              fullWidth
              onChange={(event) => {
                setFullName(event.target.value)
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='constructorShortName'
              label='Short Name'
              value={shortName ?? ""}
              fullWidth
              onChange={(event) => {
                setSuccessDisplay(false)
                setErrorDisplay(false)
                setShortName(event.target.value)
              }}
            />
          </Grid>
          <Grid
            item
            xs={color ? 10 : 12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='constructorColor'
              label='Color'
              value={color}
              fullWidth
              onChange={(event) => {
                setSuccessDisplay(false)
                setErrorDisplay(false)
                setColor(event.target.value)
              }}
            />
          </Grid>
          {color && (
            <Grid
              item
              xs={2}
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              <Chip
                sx={{
                  height: 50,
                  width: "100%",
                }}
                style={{ backgroundColor: color, color: "white" }}
                label={color}
              />
            </Grid>
          )}

          {types.map((type) => {
            const picture = pictures.find((p) => p.pictureType === type.value)
            return (
              <React.Fragment key={type.id}>
                <Grid
                  item
                  xs={picture ? 10 : 12}
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                >
                  <TextField
                    id={`constructorPicture${type.id}`}
                    label={type.value}
                    value={picture?.picture ?? ""}
                    fullWidth
                    onChange={(event) => {
                      setSuccessDisplay(false)
                      setErrorDisplay(false)
                      updatePicture(event.target.value, type.value)
                    }}
                  />
                </Grid>

                {picture?.picture != null && (
                  <Grid
                    item
                    xs={2}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <ImageListItem>
                      <img
                        srcSet={picture.picture}
                        src={picture.picture}
                        alt={type.value}
                        style={{ maxHeight: 100 }}
                        loading='lazy'
                      />
                    </ImageListItem>
                  </Grid>
                )}
              </React.Fragment>
            )
          })}
        </Grid>
        <Grid item display='flex' justifyContent='center' alignItems='center'>
          <Button size='medium' variant='contained' onClick={handleSubmit}>
            Save
          </Button>
        </Grid>
      </FormControl>
    </Container>
  )
}
